var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { TabbedShowLayout, Tab, useTranslate, TextField, useShowController } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { show } from './Style';
import { usePermissions } from 'react-admin';
import ShowRolePermissions from '../../components/permissionsmanager/ShowRolePermissions';
import { ShowViewToolbarButton, DefaultCrudToolbar } from '../../../../components';
import { PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION, PERM_ROLE_EDIT } from '../../constants/permissions';
import { hasPermission } from '../../../../helpers';
import DefaultShow from '../../../../components/show/defaultShow/DefaultShow';
var useStyles = makeStyles(show);
var RoleTitle = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    return (_jsxs("span", { children: [translate('resources.Role.name', { smart_count: 1 }), ' ', record ? "\"" + record.name + "\"" : ''] }, void 0));
};
var TabbedShowRoleLayout = function (_a) {
    var controllerProps = _a.controllerProps, classes = _a.classes, isFirstRender = _a.isFirstRender, setInitialRender = _a.setInitialRender, isLoading = _a.isLoading, props = _a.props;
    return (_jsxs(DefaultShow, __assign({}, props, controllerProps, { actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(RoleTitle, {}, void 0) }, { children: [_jsxs(TabbedShowLayout, { children: [_jsxs(Tab, __assign({ label: "resources.Role.tabs.role" }, { children: [_jsx(TextField, { source: "name", className: classes.width544 }, void 0), _jsx(TextField, { source: "description", className: classes.width544 }, void 0)] }), void 0), _jsx(Tab, __assign({ label: "resources.Role.tabs.permissions" }, { children: _jsx(ShowRolePermissions, { roleId: props.id, props: props, setInitialRender: setInitialRender, isFirstRender: isFirstRender }, void 0) }), void 0)] }, void 0), _jsx(ShowViewToolbarButton, { allowedPermissionNames: { edit: PERM_ROLE_EDIT } }, void 0)] }), void 0));
};
var SimpleShowForm = function (_a) {
    var controllerProps = _a.controllerProps, props = _a.props, classes = _a.classes;
    return (_jsxs(DefaultShow, __assign({}, props, controllerProps, { actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(RoleTitle, {}, void 0) }, { children: [_jsx(TextField, { source: "name", className: classes.width544 }, void 0), _jsx(TextField, { source: "description", className: classes.inlineGridWith50PercentWidth }, void 0), _jsx(ShowViewToolbarButton, { allowedPermissionNames: { edit: PERM_ROLE_EDIT } }, void 0)] }), void 0));
};
var RoleShow = function (props) {
    var classes = useStyles();
    var controllerProps = useShowController(props);
    var _a = usePermissions(), permissions = _a.permissions, loaded = _a.loaded;
    var allowManagePermissionsFromRoleScreen = hasPermission(permissions, PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION);
    var _b = useState(true), isFirstRender = _b[0], setFirstRender = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var setInitialRender = function (values) {
        setFirstRender(false);
    };
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            setIsLoading(false);
        }, 2000);
        return function () { return clearTimeout(timer); };
    }, [isLoading]);
    if (allowManagePermissionsFromRoleScreen) {
        return (_jsx(TabbedShowRoleLayout, { controllerProps: controllerProps, props: props, classes: classes, isFirstRender: isFirstRender, setInitialRender: setInitialRender, loading: loading, isLoading: isLoading }, void 0));
    }
    else {
        return (_jsx(SimpleShowForm, __assign({ controllerProps: controllerProps }, props, { props: props, classes: classes }), void 0));
    }
};
export default RoleShow;
