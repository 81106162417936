import { Edit } from 'react-admin';
import { DefaultCrudToolbar } from "@bedrock/ra-core";
import { makeStyles } from "@material-ui/core/styles";

import {
  FormPageTitle,
  FixedDepositFormCreateEditContainer,
  defaultStyles,
} from "@smartforms/commonui";

const useStyles = makeStyles(defaultStyles);

const FixedDepositEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit
      actions={<DefaultCrudToolbar />}
      title={
        <FormPageTitle
          titleName="resources.FixDepositEntries.name"
          titleFieldValue="name"
        />
      }
      redirect="show"
      {...props}
    >
      <FixedDepositFormCreateEditContainer
        isFront={false}
        {...props}
        classes={classes}
      />
    </Edit>
  );
};

export { FixedDepositEdit };
