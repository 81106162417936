import gql from "graphql-tag";

const formsCustomQueries = (builtQuery, fetchType, resource, params) => {
  if (resource === "Form") {
    if (fetchType === "GET_ONE") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query Form($id: String!) {
            data: Form(id: $id) {
              id
              isActive
              name
              description
              fields
              slug
              __typename
            }
          }
        `,
      };
    } else if (fetchType === "GET_LIST") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query allForms(
            $filter: ListFormFilterInput!
            $page: Int!
            $perPage: Int!
            $sortField: String
            $sortOrder: String
          ) {
            items: allForms(
              filter: $filter
              page: $page
              perPage: $perPage
              sortField: $sortField
              sortOrder: $sortOrder
            ) {
              id
              name
              slug
              description
              version
              isActive
              __typename
            }
            total: _allFormsMeta(
              filter: $filter
              page: $page
              perPage: $perPage
            ) {
              count
              __typename
            }
          }
        `,
      };
    } else if (fetchType === "UPDATE") {
      return {
        // Use the default query variables and parseResponse
        // ...builtQuery,
        ...builtQuery,
        query: gql`
          mutation updateForm(
            $id: ID!
            $slug: String!
            $name: String!
            $isActive: Boolean!
            $description: String!
            $fields: JSON!
          ) {
            data: updateForm(
              id: $id
              slug: $slug
              name: $name
              isActive: $isActive
              description: $description
              fields: $fields
            ) {
              id
              name
              description
              isActive
              __typename
            }
          }
        `,
      };
    }
  }
  return builtQuery;
};

export { formsCustomQueries };
