import DescriptionIcon from "@material-ui/icons/Description";
import { PMJJBYFormList } from "./PMJJBYFormList";
import { PMJJBYFormShow } from "./PMJJBYFormShow";
import PMJJBYFormEdit from "./PMJJBYFormEdit";

import {
  PERM_FORM_ENTRIES_LIST,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_ENTRIES_LIST,
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
};

const pmjjbyFormEntriesResource = {
  name: "PMJJBY",
  list: PMJJBYFormList,
  icon: DescriptionIcon,
  show: PMJJBYFormShow,
  edit: PMJJBYFormEdit,
  allowedPermissions: allowedPermissions,
};

export { pmjjbyFormEntriesResource };
