var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import GatedSubMenu from '../../../components/menu/GatedSubMenu';
import GatedMenuItemLink from '../../../components/menu/GatedMenuItemLink';
import UtilitiesIcon from '@material-ui/icons/TrackChanges';
import { PERM_UTILITIES_SETUP_MENU, PERM_MEDIA_MENU } from '../constants/permissions';
import { MediaIcon } from '../entities/media';
var UtilitiesSetupMenu = function (_a) {
    var onMenuClick = _a.onMenuClick, dense = _a.dense, open = _a.open;
    return (_jsx(Fragment, { children: _jsx(GatedSubMenu, __assign({ menuName: "resources.utilitiesMenu", icon: _jsx(UtilitiesIcon, {}, void 0), open: open, allowedPermissionName: PERM_UTILITIES_SETUP_MENU, dense: dense }, { children: _jsx(GatedMenuItemLink, { to: "/Media", menuName: "resources.Media.menu", leftIcon: _jsx(MediaIcon, {}, void 0), onClick: onMenuClick, allowedPermissionName: PERM_MEDIA_MENU, open: open, dense: dense }, void 0) }), void 0) }, void 0));
};
export { UtilitiesSetupMenu };
