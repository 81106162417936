import { Component, useState, useEffect } from "react";
import {
  Admin,
  Resource,
  Login,
} from "react-admin";
import "./App.css";
import polyglotI18nProvider from "ra-i18n-polyglot";

import englishMessages from "@smartforms/commonui/i18n/en";

import { withStyles } from "@material-ui/core/styles";

import {
  DefaultAppLoader,
  defaultApolloGqlAuthProvider,
  userResource,
  roleResource,
  permissionResource,
  permissionGroupResource,
  masterDataResource,
  systemDataResource,
  MediaResource,
  defaultCustomRoutes,
  defaultThemeReducer,
  resourcePermissionsChecker,
} from "@bedrock/ra-core";

import appApolloGqlDataProvider from "./dataprovider/appApolloGqlDataProvider";

import { AppLoginPage, Layout } from "./layout";
import { Dashboard } from "./modules/dashboard";
import routes from "./routes";

import { Footer } from "@smartforms/commonui";
import { apolloClient } from "@smartforms/commonui";
import { ApolloProvider } from "@apollo/react-hooks";
import { formResource } from "./modules/forms/entities/forms";
import {
  savingsFormResource,
  currentFormResource,
  fasTagFormEntriesResource,
  fixedDepositEntriesResource,
  pmsbyFormEntriesResource,
  pmjjbyFormEntriesResource
} from "./modules/forms/entities/entries";
import { branchResource } from "./modules/branch";
import { formStatResource } from "./modules/formStats";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en");

const GlobalCss = withStyles((theme) => ({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-focused, .MuiSelect-select:focus, .ra-rich-text-input .ql-editor": {
      backgroundColor: "transparent",
    },
    ".MuiFormControl-root": {
      backgroundColor: "transparent",
      padding: "0 12px 10px",
      minWidth: "256px",
    },
    ".MuiFormHelperText-contained": {
      margin: "8px 0 0",
    },
    ".MuiFilledInput-input, .MuiFilledInput-multiline": {
      padding: "27px 0 10px",
    },
    ".makeStyles-raInput-133": {
      marginTop: "0px !important"
    },
    ".MuiFilledInput-multiline.MuiFilledInput-marginDense": {
      paddingTop: "34px!important"
    },
    ".makeStyles-raInput": {
      marginTop: "0px !important"
    },
    ".MuiFilledInput-inputMultiline": {
      padding: 0,
    },
    ".MuiTableCell-root": {
      padding: "0 12px !important",
    },
    ".MuiButton-containedPrimary": {
      color: " #fff",
      backgroundColor: "#f0ba46 !important",
    },
    ".MuiButton-containedPrimary:hover": {
      color: " #fff",
      backgroundColor: "#224c02",
    },
    ".MuiCard-root": {
      marginTop: "0",
    },
    [theme.breakpoints.up("md")]: {
      ".SimpleFormIterator-form-303": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      },
      ".SimpleFormIterator-form-850": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      },
    },
  },
}))(() => null);

class App extends Component {
  state = {
    client: null,
    dataProvider: null,
    locale: "en",
  };

  async componentDidMount() {
    const dataProvider = await appApolloGqlDataProvider();
    const locale = "en";
    const client = await apolloClient();
    this.setState({ dataProvider, locale, client });
  }

  render() {
    const { dataProvider, locale, client } = this.state;

    if (!dataProvider) {
      return <DefaultAppLoader />;
    }

    const customRoutes = [...defaultCustomRoutes, ...routes];

    return (
      <ApolloProvider client={client}>
        <GlobalCss />
        <Admin
          title={process.env.REACT_APP_HEADER_LABEL}
          loginPage={AppLoginPage}
          // loginPage={AppLoginPage}
          dataProvider={dataProvider}
          customReducers={{ theme: defaultThemeReducer }}
          customRoutes={customRoutes}
          authProvider={defaultApolloGqlAuthProvider}
          dashboard={Dashboard}
          // loginPage={DefaultLogin}
          layout={Layout}
          // locale={locale}
          i18nProvider={i18nProvider}
          disableTelemetry
        >
          {(userPermissions) => [
            <Resource name="Dashboard" key="Dashboard" />,
            <Resource name="Form" {...formResource} key="Form" />,

            <Resource
              name="User"
              {...resourcePermissionsChecker(userResource, userPermissions)}
              key="Form"
            />,
            <Resource
              name="Role"
              {...resourcePermissionsChecker(
                roleResource,
                userPermissions,
                userPermissions
              )}
              key="Role"
            />,
            <Resource
              name="PermissionGroup"
              {...resourcePermissionsChecker(
                permissionGroupResource,
                userPermissions
              )}
              key="PermissionGroup"
            />,
            <Resource
              name="Permission"
              {...resourcePermissionsChecker(
                permissionResource,
                userPermissions
              )}
              key="Permission"
            />,
            // <Resource
            //   name="MasterData"
            //   {...resourcePermissionsChecker(
            //     masterDataResource,
            //     userPermissions
            //   )}
            // />,
            // <Resource
            //   name="SystemData"
            //   {...resourcePermissionsChecker(
            //     systemDataResource,
            //     userPermissions
            //   )}
            // />,
            <Resource
              name="Media"
              {...resourcePermissionsChecker(MediaResource, userPermissions)}
              key="Media"
            />,
            <Resource {...currentFormResource} key="current" />,
            <Resource {...savingsFormResource} key="savings" />,
            <Resource {...fasTagFormEntriesResource} key="fasTag" />,
            <Resource {...fixedDepositEntriesResource} key="fixedDeposit" />,
            <Resource {...pmsbyFormEntriesResource} key="pmsb" />,
            <Resource {...pmjjbyFormEntriesResource} key="pmjjby" />,
            <Resource name="Branch" {...branchResource} key="Branch" />,
            <Resource name="FormStats" {...formStatResource} key="FormStats" />,
            <Footer key="Footer" />,
          ]}
        </Admin>
        {/* <Footer /> */}
      </ApolloProvider>
    );
  }
}

export default App;
