import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from 'react-router-dom';
import { DefaultUserPreferences } from '../modules';
import ProfileShow from '../modules/profile/ProfileShow';
import ProfileEdit from '../modules/profile/ProfileEdit';
var defaultCustomRoutes = [
    _jsx(Route, { exact: true, path: "/userpreferences", render: function () { return _jsx(DefaultUserPreferences, {}, void 0); } }, void 0),
    _jsx(Route, { exact: true, path: "/my-profile", render: function () { return _jsx(ProfileShow, {}, void 0); } }, void 0),
    _jsx(Route, { path: "/my-profile/edit", render: function () { return _jsx(ProfileEdit, {}, void 0); } }, void 0)
];
export { defaultCustomRoutes };
