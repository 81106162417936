export var PERM_UTILITIES_SETUP_MENU = 'view-utilities-management-menu';
//Translations
export var PERM_LANGUAGE_MENU = 'view-languages-management-menu';
export var PERM_LANGUAGE_CREATE = 'create-language';
export var PERM_LANGUAGE_EDIT = 'update-language';
export var PERM_LANGUAGE_DELETE = 'delete-language';
export var PERM_LANGUAGE_LIST = 'list-languages';
export var PERM_LANGUAGE_SHOW = 'show-languages';
export var PERM_LANGUAGE_READONLY = 'readonly-language';
//Settings
export var PERM_SETTING_MENU = 'view-setting-management-menu';
export var PERM_SETTING_CREATE = 'create-setting';
export var PERM_SETTING_EDIT = 'update-setting';
export var PERM_SETTING_DELETE = 'delete-setting';
export var PERM_SETTING_LIST = 'list-settings';
export var PERM_SETTING_SHOW = 'show-settings';
export var PERM_SETTING_READONLY = 'readonly-setting';
//Media
export var PERM_MEDIA_MENU = 'view-manage-media-menu';
export var PERM_MEDIA_LIST = 'list-media';
export var PERM_MEDIA_DELETE = 'delete-media';
