import DescriptionIcon from "@material-ui/icons/Description";
import { FixedDepositEntriesList } from "./FixedDepositEntriesList";
import { FixedDepositEdit } from "./FixedDepositEdit";
import { FixedDepositEntriesShow } from "./FixedDepositEntriesShow";

import {
  PERM_FORM_ENTRIES_LIST,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_ENTRIES_LIST,
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
};

const fixedDepositEntriesResource = {
  name: "FixedDeposit",
  list: FixedDepositEntriesList,
  icon: DescriptionIcon,
  show: FixedDepositEntriesShow,
  edit: FixedDepositEdit,
  allowedPermissions: allowedPermissions,
};

export { fixedDepositEntriesResource };
