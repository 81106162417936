var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import ActionDelete from '@material-ui/icons/Delete';
import { useTranslate, useDeleteMany, useRefresh, useNotify, useUnselectAll, CRUD_DELETE_MANY } from 'ra-core';
import AlertError from '@material-ui/icons/ErrorOutline';
import ActionCheck from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import { usePermissions } from 'ra-core';
import { hasPermission } from '../../helpers/auth';
var useStyles = makeStyles(function (theme) { return ({
    contentText: {
        minWidth: 400
    },
    deleteButton: {
        padding: ' 4px 5px',
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    },
    iconPaddingStyle: {
        paddingRight: '0.5em'
    },
    confirmPrimary: {
        color: theme.palette.primary.main
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
}); });
var BulkDeleteWithConfirmWithUndoButton = function (_a) {
    var _b;
    var permissionName = _a.permissionName, labels = _a.labels, undoable = _a.undoable, CancelIcon = _a.CancelIcon, ConfirmIcon = _a.ConfirmIcon, basePath = _a.basePath, classesOverride = _a.classes, deleteIcon = _a.deleteIcon, confirmColor = _a.confirmColor, onClick = _a.onClick, resource = _a.resource, selectedIds = _a.selectedIds, rest = __rest(_a, ["permissionName", "labels", "undoable", "CancelIcon", "ConfirmIcon", "basePath", "classes", "deleteIcon", "confirmColor", "onClick", "resource", "selectedIds"]);
    var _c = useState(false), isOpen = _c[0], setOpen = _c[1];
    var classes = useStyles();
    var notify = useNotify();
    var unselectAll = useUnselectAll();
    var refresh = useRefresh();
    var translate = useTranslate();
    var permissions = usePermissions().permissions;
    var permission = hasPermission(permissions, permissionName);
    var smart_count = selectedIds.length;
    var _d = useDeleteMany(resource, selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess: function () {
            notify("" + smart_count + ' ' + labels.success, 'info', {
                smart_count: selectedIds.length
            }, undoable);
            unselectAll(resource);
            refresh();
        },
        onFailure: function (error) {
            return notify(typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error', 'warning');
        },
        undoable: undoable
    }), deleteMany = _d[0], loading = _d[1].loading;
    var handleClick = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleDelete = function (e) {
        deleteMany();
        if (typeof onClick === 'function') {
            onClick(e);
        }
    };
    if (permission === false)
        return null;
    else
        return (_jsxs(Fragment, { children: [_jsxs(Button, __assign({ onClick: function (e) { return handleClick(); }, className: classes.deleteButton }, { children: [deleteIcon, labels.deleteButton] }), void 0), _jsxs(Dialog, __assign({ open: isOpen, onClose: handleClose, "aria-labelledby": "alert-dialog-title" }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: labels.messageHeader }), void 0), _jsx(DialogContent, { children: _jsx(DialogContentText, __assign({ className: classes.contentText }, { children: labels.message }), void 0) }, void 0), _jsxs(DialogActions, { children: [_jsxs(Button, __assign({ disabled: loading, onClick: handleClose }, { children: [_jsx(CancelIcon, { className: classes.iconPaddingStyle }, void 0), labels.cancelButton] }), void 0), _jsxs(Button, __assign({ disabled: loading, onClick: handleDelete, className: classnames('ra-confirm', (_b = {},
                                        _b[classes.confirmWarning] = confirmColor === 'warning',
                                        _b[classes.confirmPrimary] = confirmColor === 'primary',
                                        _b)), autoFocus: true }, { children: [_jsx(ConfirmIcon, { className: classes.iconPaddingStyle }, void 0), labels.confirmButton] }), void 0)] }, void 0)] }), void 0)] }, void 0));
};
BulkDeleteWithConfirmWithUndoButton.propTypes = {
    permissionName: PropTypes.string,
    labels: PropTypes.object,
    basePath: PropTypes.string,
    classes: PropTypes.object,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    deleteIcon: PropTypes.element
};
BulkDeleteWithConfirmWithUndoButton.defaultProps = {
    deleteIcon: _jsx(ActionDelete, {}, void 0),
    classes: {},
    confirmColor: 'primary',
    ConfirmIcon: ActionCheck,
    CancelIcon: AlertError,
    isOpen: false,
    undoable: true,
    labels: {
        deleteButton: 'Delete',
        messageHeader: 'Delete the selected items?',
        message: 'Are you sure you want to delete the selected items?',
        cancelButton: 'Cancel',
        confirmButton: 'Confirm',
        success: 'items are deleted'
    }
};
export default BulkDeleteWithConfirmWithUndoButton;
