import PermissionGroupIcon from '@material-ui/icons/GroupWork';
import PermissionGroupList from './PermissionGroupList';
import PermissionGroupShow from './PermissionGroupShow';
import { PERM_PERMISSION_GROUP_LIST, PERM_PERMISSION_GROUP_CREATE, PERM_PERMISSION_GROUP_EDIT, PERM_PERMISSION_GROUP_SHOW } from '../../constants/permissions';
var allowedPermissions = {
    list: PERM_PERMISSION_GROUP_LIST,
    create: PERM_PERMISSION_GROUP_CREATE,
    edit: PERM_PERMISSION_GROUP_EDIT,
    show: PERM_PERMISSION_GROUP_SHOW
};
var permissionGroupResource = {
    name: 'PermissionGroup',
    icon: PermissionGroupIcon,
    list: PermissionGroupList,
    show: PermissionGroupShow,
    allowedPermissions: allowedPermissions
};
export default permissionGroupResource;
