export const styles = (theme) => ({
  floatLeft: {
    float: "left",
  },
  floatLeftWithMarginLeft: {
    float: "left",
    [theme.breakpoints.up("xs")]: {
      marginLeft: 32,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  clearWithFloatLeft: {
    float: "left",
    clear: "left",
  },
  width544: { width: 544 },
  maxWidth544: { maxWidth: 544 },
  paddingLeft12: {
    paddingLeft: 12,
  },
  flex: {
    display: "flex",
  },
});

export const show = (theme) => ({
  inlineGridWith50PercentWidth: {
    [theme.breakpoints.up("xs")]: {
      display: "inline-grid",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  width544: { width: 544 },
});
