export var styles = function (theme) {
    var _a;
    return ({
        clearWithFloatLeft: {
            float: 'left',
            clear: 'left'
        },
        floatLeftWithMarginLeft: {
            float: 'left',
            marginLeft: 32
        },
        marginTop20: {
            marginTop: '25px'
        },
        actionButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 0 14px 8px'
        },
        inlineGridWith50PercentWidth: (_a = {},
            _a[theme.breakpoints.up('xs')] = {
                display: 'inline-grid',
                width: '50%'
            },
            _a[theme.breakpoints.down('xs')] = {
                width: '100%'
            },
            _a)
    });
};
