import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import { DefaultCard } from "@smartforms/commonui";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { FormSummaryStatsCard } from "./cards/FormSummaryStatsCard";

const useStyles = makeStyles((theme) => ({
  flex: { display: "flex", flexDirection: "row" },
}));

const calculateBranches = (branchData) => {
  return branchData?.items?.map((item) => (
    {
      value: item.id,
      label: item.name,
    }
  ));
}

const Dashboard = () => {

  const [branchId, setBranchId] = useState(null);
  const [defaultBranchOption, setDefaultBranchOption] = useState(null);

  const handleBranchIdChanged = (option) => {
    setDefaultBranchOption(option);
    setBranchId(option?.value);
  }

  const [handleSubmit, { loading, data, error, refetch }] = useLazyQuery(
    dashboardQuery,
    {
      displayName: "DashboardQuery",
      fetchPolicy: "network-only",
    }
  );

  const { data: branchesData } = useQuery(allBranchesQuery, {
    displayName: "BranchDataQuery",
    fetchPolicy: "cache-and-network",
    variables: {
      page: 0,
      perPage: 100,
      sortField: "id",
      sortOrder: "DESC",
      filter: {},
    },
  });

  const allBranchesOptions = calculateBranches(branchesData);

  useEffect(() => {
    handleSubmit({ variables: { branchId: parseInt(branchId) } });
  }, [branchId]);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error) {
    return <div>An error occured!</div>;
  }

  return <DefaultCard
    data={data?.data}
    allBranchesOptions={allBranchesOptions}
    defaultBranchOption={defaultBranchOption}
    branchId={branchId}
  >
    <FormSummaryStatsCard
      totalFormEntriesCount={data?.data?.totalFormEntriesCount}
      totalMediaSize={data?.data?.totalMediaSize}
      summaryStatusCount={data?.data?.summaryStatusCount}
      formsSummary={data?.data?.formsSummary}
      refetch={refetch}
      allBranchesOptions={allBranchesOptions}
      defaultBranchOption={defaultBranchOption}
      handleBranchIdChanged={handleBranchIdChanged}
      branchId={branchId}
      />
  </DefaultCard>
};

const dashboardQuery = gql`
  query Dashboard($branchId: Int) {
    data: Dashboard(branchId: $branchId) {
      firstName
      lastName
      totalFormEntriesCount
      totalMediaSize
      summaryStatusCount
      last5DaysTotal
      formsSummary {
        formId
        formName
        totalFormEntriesCount
        totalReceivedFormEntriesCount
        totalProcessedFormEntriesCount
        mediaFileSize
        formsSlug
      }
      branchId
    }
  }
`;

const allBranchesQuery = gql`
  query allBranches(
    $filter: ListBranchFilterInput!
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
  ) {
    items: allBranches(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      id
      name
    }
  }
`;

export { Dashboard };
