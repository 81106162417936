import { permissionGroupCustomQueries } from '../modules/access/entities/permissionGroups/permissionGroupCustomQueries';
import { permissionCustomQueries } from '../modules/access/entities/permissions/permissionCustomQueries';
import { roleCustomQueries } from '../modules/access/entities/roles/roleCustomQueries';
import { userCustomQueries } from '../modules/access/entities/users/userCustomQueries';
import { mediaCustomQueries } from '../modules/utilities/entities/media/mediaCustomQueries';
var CoreCustomQueries = function (builtQuery, fetchType, resource, params) {
    builtQuery = permissionGroupCustomQueries(builtQuery, fetchType, resource, params);
    builtQuery = permissionCustomQueries(builtQuery, fetchType, resource, params);
    builtQuery = roleCustomQueries(builtQuery, fetchType, resource, params);
    builtQuery = userCustomQueries(builtQuery, fetchType, resource, params);
    builtQuery = mediaCustomQueries(builtQuery, fetchType, resource, params);
    return builtQuery;
};
export { CoreCustomQueries };
