import DefaultGatedListActions from "@bedrock/ra-core/dist/esm/components/list/actions/DefaultGatedListActions";
import ExportFormEntries from "./ExportForms";
import ExportForms from "./ExportForms";
const FormListActions = ({
  allowedPermissionNames,
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  permanentFilter,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  data,
  ids,
  page,
  perPage,
  ...props
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <DefaultGatedListActions
        allowedPermissionNames={allowedPermissionNames}
        bulkActions={bulkActions}
        basePath={basePath}
        currentSort={currentSort}
        displayedFilters={displayedFilters}
        filters={filters}
        filterValues={filterValues}
        onUnselectItems={onUnselectItems}
        resource={resource}
        selectedIds={selectedIds}
        showFilter={showFilter}
        total={total}
      >
        <ExportForms
          total={total}
          resource={resource}
          currentSort={currentSort}
          filterValues={filterValues}
          label="resources.Form.buttons.export"
        />
      </DefaultGatedListActions>
    </div>
  );
};

export default FormListActions;
