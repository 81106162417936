import { required, minLength, maxLength, number, email } from "react-admin";

const nameRequiredValidationMessage =
  "resources.Branch.validations.name.required";
const addressRequiredValidationMessage =
  "resources.Branch.validations.address.required";
const cityRequiredValidationMessage =
  "resources.Branch.validations.city.required";
const pinCodeRequiredValidationMessage =
  "resources.Branch.validations.pinCode.required";
const contactRequiredValidationMessage =
  "resources.Branch.validations.contact.required";
const ifscCodeRequiredValidationMessage =
  "resources.Branch.validations.ifscCode.required";
const micrCodeRequiredValidationMessage =
  "resources.Branch.validations.micrCode.required";
const notificationEmailRequiredValidationMessage =
  "resources.Branch.validations.notificationEmail.required";

const name = [
  required(nameRequiredValidationMessage),
  minLength(2),
  maxLength(100),
];
const address = [
  required(addressRequiredValidationMessage),
  minLength(2),
  maxLength(400),
];
const city = [
  required(cityRequiredValidationMessage),
  minLength(2),
  maxLength(100),
];
const pinCode = [
  required(pinCodeRequiredValidationMessage),
  minLength(2),
  maxLength(10),
];
const contact = [
  required(contactRequiredValidationMessage),
  minLength(2),
  maxLength(12),
];
const ifscCode = [
  required(ifscCodeRequiredValidationMessage, minLength(2), maxLength(20)),
];
const micrCode = [
  required(micrCodeRequiredValidationMessage),
  minLength(2),
  maxLength(20),
];
const notificationEmail = [
  required(notificationEmailRequiredValidationMessage),
  email(),
];
const description = [minLength(2), maxLength(500)];

export const validations = {
  name,
  address,
  city,
  pinCode,
  contact,
  ifscCode,
  micrCode,
  notificationEmail,
  description,
};
