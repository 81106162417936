var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TabbedForm, FormTab, TextInput, Create, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Validations from './Validations';
import { styles } from './Style';
import CreateRolePermissionsManagerInput from '../../components/permissionsmanager/CreateRolePermissionsManagerInput';
import { hasPermission } from '../../../../helpers';
import { PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION } from '../../constants/permissions';
import { DefaultCreate } from '../../../../components';
import { DefaultCrudToolbar } from '../../../../components';
var useStyles = makeStyles(styles);
var SimpleCreateForm = function (_a) {
    var props = _a.props, classes = _a.classes;
    return (_jsx(DefaultCreate, __assign({ actions: _jsx(DefaultCrudToolbar, {}, void 0), redirect: "show" }, props, { children: _jsxs(FormTab, __assign({ label: "resources.Role.tabs.role" }, { children: [_jsx(TextInput, { autoFocus: true, source: "name", formClassName: classes.width544, validate: Validations.name }, void 0), _jsx(TextInput, { autoFocus: true, source: "description", multiline: true, formClassName: classes.width544, validate: Validations.description, resettable: true }, void 0)] }), void 0) }), void 0));
};
var TabbedCreateForm = function (_a) {
    var props = _a.props, classes = _a.classes;
    return (_jsx(Create, __assign({}, props, { children: _jsxs(TabbedForm, __assign({ redirect: "show" }, { children: [_jsxs(FormTab, __assign({ label: "resources.Role.tabs.role" }, { children: [_jsx(TextInput, { autoFocus: true, source: "name", formClassName: classes.width544, validate: Validations.name }, void 0), _jsx(TextInput, { autoFocus: true, source: "description", multiline: true, formClassName: classes.width544, validate: Validations.description, resettable: true }, void 0)] }), void 0), _jsx(FormTab, __assign({ label: "resources.Role.tabs.permissions" }, { children: _jsx(CreateRolePermissionsManagerInput, { props: props }, void 0) }), void 0)] }), void 0) }), void 0));
};
var RoleCreate = function (props) {
    var classes = useStyles();
    var _a = usePermissions(), permissions = _a.permissions, loaded = _a.loaded;
    var allowManagePermissionsFromRoleScreen = hasPermission(permissions, PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION);
    if (allowManagePermissionsFromRoleScreen) {
        return _jsx(TabbedCreateForm, { classes: classes, props: props }, void 0);
    }
    else {
        return _jsx(SimpleCreateForm, { classes: classes, props: props }, void 0);
    }
};
export default RoleCreate;
