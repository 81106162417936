import DescriptionIcon from "@material-ui/icons/Description";
import { PMSBYFormList } from "./PMSBYFormList";
import { PMSBYFormShow } from "./PMSBYFormShow";
import PMSBYFormEdit from "./PMSBYFormEdit";

import {
  PERM_FORM_ENTRIES_LIST,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_ENTRIES_LIST,
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
};

const pmsbyFormEntriesResource = {
  name: "PMSBY",
  list: PMSBYFormList,
  icon: DescriptionIcon,
  show: PMSBYFormShow,
  edit: PMSBYFormEdit,
  allowedPermissions: allowedPermissions,
};

export { pmsbyFormEntriesResource };
