var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslate, TextField, EmailField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { show } from './Style';
import DefaultCrudToolbar from '../../../../components/customToolbar/DefaultCrudToolbar';
import { PERM_USER_EDIT } from '../../constants/permissions';
import { ShowViewToolbarButton } from '../../../../components';
import Chip from '@material-ui/core/Chip';
import DefaultShow from '../../../../components/show/defaultShow/DefaultShow';
var useStyles = makeStyles(show);
var UserTitle = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    return (_jsxs("span", { children: [translate('resources.User.name', { smart_count: 1 }), ' ', record ? "\"" + record.firstName + "\"" : ''] }, void 0));
};
var RolesField = function (_a) {
    var record = _a.record;
    return (_jsx("span", { children: record.roles.map(function (item) { return (_jsx(Chip, { style: { margin: '3px' }, label: item.name }, item)); }) }, void 0));
};
var UserShow = function (props) {
    var classes = useStyles();
    return (_jsxs(DefaultShow, __assign({}, props, { actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(UserTitle, {}, void 0) }, { children: [_jsx(TextField, { source: "firstName", className: classes.width544 }, void 0), _jsx(TextField, { source: "lastName", className: classes.width544 }, void 0), _jsx(EmailField, { source: "email", className: classes.width544 }, void 0), _jsx(TextField, { source: "role", className: classes.width544 }, void 0), _jsx(RolesField, { label: "resources.User.fields.relationalFields.roles" }, void 0), _jsx(ShowViewToolbarButton, { allowedPermissionNames: { edit: PERM_USER_EDIT } }, void 0)] }), void 0));
};
export default UserShow;
