export var PERM_ACCESS_MENU = 'view-access-management-menu';
//Users
export var PERM_USER_MENU = 'view-user-management-menu';
export var PERM_USER_LIST = 'list-users';
export var PERM_USER_CREATE = 'create-user';
export var PERM_USER_EDIT = 'update-user';
export var PERM_USER_SHOW = 'show-users';
export var PERM_USER_DELETE = 'delete-user';
export var PERM_USER_ACTION_EXPORT = 'export-user';
//Roles
export var PERM_ROLE_MENU = 'view-role-management-menu';
export var PERM_ROLE_LIST = 'list-roles';
export var PERM_ROLE_CREATE = 'create-role';
export var PERM_ROLE_EDIT = 'update-role';
export var PERM_ROLE_SHOW = 'show-roles';
export var PERM_ROLE_DELETE = 'delete-role';
export var PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION = 'role-manage-permission-action';
//Permission Groups
export var PERM_PERMISSION_GROUP_MENU = 'view-permission-groups-management-menu';
export var PERM_PERMISSION_GROUP_LIST = 'list-permission-groups';
export var PERM_PERMISSION_GROUP_CREATE = 'create-permission-group';
export var PERM_PERMISSION_GROUP_EDIT = 'update-permission-group';
export var PERM_PERMISSION_GROUP_DELETE = 'delete-permission-group';
export var PERM_PERMISSION_GROUP_SHOW = 'show-permission-groups';
export var PERM_PERMISSION_GROUP_ACTION_EXPORT = 'export-permission-group';
//Permissions
export var PERM_PERMISSION_MENU = 'view-permissions-management-menu';
export var PERM_PERMISSION_LIST = 'list-permissions';
export var PERM_PERMISSION_CREATE = 'create-permission';
export var PERM_PERMISSION_EDIT = 'update-permission';
export var PERM_PERMISSION_SHOW = 'show-permissions';
export var PERM_PERMISSION_DELETE = 'delete-permission';
