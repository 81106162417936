var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField, BooleanField, NumberField } from 'react-admin';
import DefaultGatedListActions from '../../../../components/list/actions/DefaultGatedListActions';
import { PERM_PERMISSION_DELETE } from '../../constants/permissions';
import DefaultList from '../../../../components/list/defaultList/DefaultList';
var allowedPermissionNames = {
    edit: PERM_PERMISSION_DELETE
};
var PermissionList = function (props) { return (_jsxs(DefaultList, __assign({}, props, { sort: { field: 'sort_order', order: 'ASC' }, bulkActionButtons: false, actions: _jsx(DefaultGatedListActions, { allowedPermissionNames: allowedPermissionNames }, void 0) }, { children: [_jsx(TextField, { label: "resources.Permission.fields.relationalFields.permissionGroupName", source: "permissionGroup.name" }, void 0), _jsx(TextField, { source: "label" }, void 0), _jsx(TextField, { source: "name" }, void 0), _jsx(TextField, { source: "description" }, void 0), _jsx(TextField, { source: "type" }, void 0), _jsx(NumberField, { source: "sortOrder" }, void 0), _jsx(BooleanField, { source: "isActive" }, void 0)] }), void 0)); };
export default PermissionList;
