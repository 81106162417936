import { useTranslate } from "react-admin";
import { defaultShow } from "@smartforms/commonui";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(defaultShow);

const SignatureField = ({ label }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.signatureFieldOuter}>
      <div className={classes.signatureFieldInner}>{translate(label)}</div>
    </div>
  );
};

export { SignatureField };
