import DefaultGatedListActions from "@bedrock/ra-core/dist/esm/components/list/actions/DefaultGatedListActions";
import { BackButton } from "@bedrock/ra-core";
import { ServerExportButton } from "@bedrock/ra-components";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import { usePermissions } from "react-admin";
import { PERM_FORM_ENTRIES_BULK_EXPORT } from "@smartforms/commonui";

const FormEntriesListAction = ({
  allowedPermissionNames,
  downloadApiUrl,
  filters,
}) => {
  const { loaded, permissions } = usePermissions();
  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <DefaultGatedListActions
        allowedPermissionNames={allowedPermissionNames}
        filters={filters}
      >
        {loaded &&
          hasPermission(permissions, PERM_FORM_ENTRIES_BULK_EXPORT) && (
            <ServerExportButton downloadApiUrl={downloadApiUrl} />
          )}
        <BackButton to="/Form" />
      </DefaultGatedListActions>
    </div>
  );
};

export { FormEntriesListAction };
