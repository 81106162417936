import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import { stringify } from "query-string";
import DnsIcon from "@material-ui/icons/Dns";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const LinkToRelatedEntries = ({ record, source }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const slug = get(record, source);

  return (
    <Tooltip title={translate("resources.Form.actions.viewEntry.label")}>
      <Button
        size="small"
        color="primary"
        component={Link}
        to={{
          pathname: slug,
        }}
        className={classes.link}
      >
        <DnsIcon className={classes.icon} />
        {translate("resources.Form.actions.viewEntry.label")}
      </Button>
    </Tooltip>
  );
};

export { LinkToRelatedEntries };
