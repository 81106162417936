import {
  TextField,
  useTranslate,
  RichTextField,
  useShowController,
  ArrayField,
  Datagrid,
  EmailField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { show } from "./styles";
import { DefaultCrudToolbar, ShowViewToolbarButton } from "@bedrock/ra-core";
import DefaultShow from "@bedrock/ra-core/dist/esm/components/show/defaultShow/DefaultShow";
import { PERM_BRANCH_EDIT } from "@smartforms/commonui";

const useStyles = makeStyles(show);

const Title = ({ record }) => {
  const translate = useTranslate();

  return (
    <span>
      {translate("resources.Branch.name", { smart_count: 1 })}{" "}
      {record && `"${record.name}"`}
    </span>
  );
};

const BranchShow = (props) => {
  const classes = useStyles();
  const controllerProps = useShowController(props);

  return (
    <DefaultShow
      {...props}
      {...controllerProps}
      actions={<DefaultCrudToolbar />}
      title={<Title />}
      ShowViewToolbarButton={
        <ShowViewToolbarButton
          allowedPermissionNames={{ edit: PERM_BRANCH_EDIT }}
        />
      }
    >
      <TextField
        source="name"
        className={classes.inlineGridWith50PercentWidth}
      />
      <TextField
        source="address"
        className={classes.inlineGridWith50PercentWidth}
      />
      <TextField
        source="notificationEmail"
        className={classes.inlineGridWith50PercentWidth}
      />
      <RichTextField
        source="description"
        className={classes.inlineGridWith50PercentWidth}
      />

      <ArrayField source="users">
        <Datagrid>
          <TextField
            source="firstName"
            className={classes.inlineGridWith50PercentWidth}
          />

          <TextField
            source="lastName"
            className={classes.inlineGridWith50PercentWidth}
          />
          <EmailField
            source="email"
            className={classes.inlineGridWith50PercentWidth}
          />
        </Datagrid>
      </ArrayField>
    </DefaultShow>
  );
};

export { BranchShow };
