import {
  TextField,
  Datagrid,
  List,
  Filter,
  SearchInput,
  SelectInput,
  SelectField,
  DateField,
} from "react-admin";

import {
  PostBulkActionButtons,
  DefaultFormEntriesPagination,
  FormEntriesListAction,
} from "../common";
import {
  NameField,
  FORM_ID_SAVINGS,
  COMMON_DATE_TIME_FORMAT,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_SHOW,
  PERM_FORM_ENTRIES_LIST_CREATE,
  PERM_FORM_ENTRIES_LIST_DELETE,
  PERM_FORM_ENTRIES_BULK_DELETE,
  BranchSelectDropdownInput,
  formEntryStatusChoices,
  vehicleTypeChoices,
  FormEntryDropdownListActionButton,
} from "@smartforms/commonui";

const allowedPermissionNames = {
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  show: PERM_FORM_ENTRIES_LIST_SHOW,
  create: PERM_FORM_ENTRIES_LIST_CREATE,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
  export: PERM_FORM_ENTRIES_LIST_SHOW,
};

const DefaultFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="status"
      optionText="name"
      optionValue="id"
      choices={formEntryStatusChoices}
    />
    <BranchSelectDropdownInput/>
  </Filter>
);

const hasButtonPermission = {
  delete: true,
  edit: true,
  show: true,
};

const downloadApiUrl = `/form-entry/exports/csv/${FORM_ID_SAVINGS}`;

const SavingsList = (props) => {
  return (
    <List
      {...props}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<DefaultFilter />}
      filterDefaultValues={{ formId: FORM_ID_SAVINGS }}
      actions={
        <FormEntriesListAction
          allowedPermissionNames={allowedPermissionNames}
          downloadApiUrl={downloadApiUrl}
        />
      }
      bulkActionButtons={
        <PostBulkActionButtons
          bulkDeletePermissionName={PERM_FORM_ENTRIES_BULK_DELETE}
          {...props}
        />
      }
      pagination={<DefaultFormEntriesPagination />}
    >
      <Datagrid>
        <TextField
          label="components.app.branch.name"
          source="branch.name"
          sortable={false}
        />
        <TextField
          source="uuid"
          label="resources.Common.master.uuid.label"
          sortable={false}
        />
        <NameField
          source={"data.master.name"}
          label="components.app.name.title"
          sortable={false}
        />

        <TextField
          source="data.master.mobile"
          label="resources.Common.master.mobile.label"
          sortable={false}
        />
        <TextField
          source="data.master.email"
          label="resources.Common.master.email.label"
          sortable={false}
        />
        <SelectField
          source="status"
          choices={formEntryStatusChoices}
          sortable={false}
        />
        <DateField
          source="createdAt"
          sortBy="created_at"
          showTime={true}
          options={COMMON_DATE_TIME_FORMAT}
        />
        <FormEntryDropdownListActionButton
          allowedPermissionNames={allowedPermissionNames}
          hasButtonPermission={hasButtonPermission}
        />
      </Datagrid>
    </List>
  );
};

export { SavingsList };
