var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Datagrid, List, Filter, SearchInput, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { defaultListStyle } from '../defaultListStyle';
var perPageOptions = [10, 25, 50, 100, 500];
var RowsPerPageOptions = function (props) { return (_jsx(Pagination, __assign({ rowsPerPageOptions: perPageOptions }, props), void 0)); };
var DefaultFilter = function (_a) {
    var props = __rest(_a, []);
    return (_jsx(Filter, __assign({}, props, { children: _jsx(SearchInput, { source: "q", alwaysOn: true }, void 0) }), void 0));
};
var useStyles = makeStyles(defaultListStyle);
var DefaultList = function (_a) {
    var perPage = _a.perPage, pagination = _a.pagination, filters = _a.filters, sortField = _a.sortField, sortOrder = _a.sortOrder, sort = _a.sort, style = _a.style, bulkActionButtons = _a.bulkActionButtons, actions = _a.actions, children = _a.children, props = __rest(_a, ["perPage", "pagination", "filters", "sortField", "sortOrder", "sort", "style", "bulkActionButtons", "actions", "children"]);
    var classes = useStyles();
    var listSort = sort ? sort : { field: sortField, order: sortOrder };
    return (_jsx(List, __assign({}, props, { perPage: perPage, pagination: pagination, filters: filters, sort: listSort, style: style, actions: actions, bulkActionButtons: bulkActionButtons }, { children: _jsx(Datagrid, __assign({ id: "printTable", classes: classes }, { children: children }), void 0) }), void 0));
};
DefaultList.defaultProps = {
    perPage: 50,
    pagination: _jsx(RowsPerPageOptions, {}, void 0),
    filters: _jsx(DefaultFilter, {}, void 0),
    style: { marginTop: '20px' },
    sortOrder: 'ASC'
};
export default DefaultList;
