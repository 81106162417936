import Select from "react-select";

const BranchSelect = ({
  allBranchesOptions,
  selectedBranch,
  defaultBranchOption,
  onChange,
  ...rest
}) => {
  return (
    <Select
      options={allBranchesOptions}
      onChange={onChange}
      defaultValue={defaultBranchOption}
      {...rest}
    />
  );
};

export { BranchSelect };
