import {
  TextField,
  Filter,
  SearchInput,
  SelectInput,
  SelectField,
  DateField,
} from "react-admin";
import {
  PostBulkActionButtons,
  DefaultFormEntriesPagination,
  FormEntriesListAction,
} from "../common";

import DefaultList from "@bedrock/ra-core/dist/esm/components/list/defaultList/DefaultList";

import {
  FORM_ID_PMJJBY,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_SHOW,
  PERM_FORM_ENTRIES_LIST_CREATE,
  PERM_FORM_ENTRIES_LIST_DELETE,
  PERM_FORM_ENTRIES_BULK_DELETE,
  COMMON_DATE_TIME_FORMAT,
  FormEntryDropdownListActionButton,
  BranchSelectDropdownInput,
  formEntryStatusChoices,
  NameField,
} from "@smartforms/commonui";

const allowedPermissionNames = {
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  show: PERM_FORM_ENTRIES_LIST_SHOW,
  create: PERM_FORM_ENTRIES_LIST_CREATE,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
  export: PERM_FORM_ENTRIES_LIST_SHOW,
};

const hasButtonPermission = {
  delete: true,
  edit: true,
  show: true,
};

const DefaultFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="status"
      optionText="name"
      optionValue="id"
      choices={formEntryStatusChoices}
    />
    <BranchSelectDropdownInput />
  </Filter>
);

const downloadApiUrl = `/form-entry/exports/csv/${FORM_ID_PMJJBY}`;

const PMJJBYFormList = (props) => {
  return (
    <DefaultList
      {...props}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<DefaultFilter />}
      filterDefaultValues={{ formId: FORM_ID_PMJJBY }}
      actions={
        <FormEntriesListAction
          allowedPermissionNames={allowedPermissionNames}
          downloadApiUrl={downloadApiUrl}
        />
      }
      bulkActionButtons={
        <PostBulkActionButtons
          bulkDeletePermissionName={PERM_FORM_ENTRIES_BULK_DELETE}
          {...props}
        />
      }
      pagination={<DefaultFormEntriesPagination />}
    >
      <TextField
        label="components.app.branch.name"
        source="branch.name"
        sortable={false}
      />
      <TextField
        source="uuid"
        label="resources.Common.master.uuid.label"
        sortable={false}
      />
      <NameField
        source="data.master.name"
        label="resources.Common.nomination.name"
        sortable={false}
      />
      <TextField
        source="data.master.email"
        label="resources.Common.master.email.label"
        sortable={false}
      />
      <TextField
        source="data.master.agent.name"
        label="resources.Common.master.agent.name"
        sortable={false}
      />

      <SelectField
        source="status"
        choices={formEntryStatusChoices}
        sortable={false}
      />
      <DateField
        source="createdAt"
        sortBy="created_at"
        showTime={true}
        options={COMMON_DATE_TIME_FORMAT}
      />

      <FormEntryDropdownListActionButton
        allowedPermissionNames={allowedPermissionNames}
        hasButtonPermission={hasButtonPermission}
      />
    </DefaultList>
  );
};

export { PMJJBYFormList };
