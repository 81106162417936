import DescriptionIcon from "@material-ui/icons/Description";
import { SavingsList } from "./SavingsList";
import { SavingsEdit } from "./SavingsEdit";
import { SavingsFormShow } from "./SavingsFormShow";

import {
  PERM_FORM_ENTRIES_LIST,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_ENTRIES_LIST,
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
};

const savingsFormResource = {
  name: "Savings",
  list: SavingsList,
  icon: DescriptionIcon,
  show: SavingsFormShow,
  edit: SavingsEdit,
  allowedPermissions: allowedPermissions,
  options: { label: "FE" },
};

export { savingsFormResource };
