import { useState } from "react";
import { DefaultCrudToolbar, EditViewToolbarButton } from "@bedrock/ra-core";
import DefaultEdit from "@bedrock/ra-core/dist/esm/components/edit/defaultEdit/DefaultEdit";
import {
  FormPageTitle,
  PMJJBYFormCreateEditContainer,
} from "@smartforms/commonui";

const PMJJBYFormEdit = (props) => {
  const [showSaveButton, setIsShowSaveButton] = useState(false);

  const handleSaveButton = (value) => {
    setIsShowSaveButton(value);
  };

  return (
    <DefaultEdit
      actions={<DefaultCrudToolbar />}
      title={
        <FormPageTitle
          titleName="resources.PMJJBYForm.name"
          titleFieldValue="name"
        />
      }
      toolbar={
        <EditViewToolbarButton
          allowedPermissionNames={{ delete: false }}
          showEditButton={showSaveButton}
        />
      }
      {...props}
    >
      <PMJJBYFormCreateEditContainer
        isFront={false}
        {...props}
        toggleSaveButton={handleSaveButton}
      />
    </DefaultEdit>
  );
};

export default PMJJBYFormEdit;
