import DescriptionIcon from "@material-ui/icons/Description";
import { CurrentList } from "./CurrentList";
import { CurrentEdit } from "./CurrentEdit";
import { CurrentFormShow } from "./CurrentFormShow";

import {
  PERM_FORM_ENTRIES_LIST,
  PERM_FORM_ENTRIES_LIST_EDIT,
  PERM_FORM_ENTRIES_LIST_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_ENTRIES_LIST,
  edit: PERM_FORM_ENTRIES_LIST_EDIT,
  delete: PERM_FORM_ENTRIES_LIST_DELETE,
};

const currentFormResource = {
  name: "Current",
  list: CurrentList,
  icon: DescriptionIcon,
  show: CurrentFormShow,
  edit: CurrentEdit,
  allowedPermissions: allowedPermissions,
  options: { label: "FE" },
};

export { currentFormResource };
