import { jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var AppMenu = function (_a) {
    var children = _a.children;
    return _jsxs(Fragment, { children: [" ", children] }, void 0);
};
/*class AppMenu extends Component {

    static propTypes = {
        onMenuClick: PropTypes.func,
    };

    render() {
        const { onMenuClick } = this.props;
        return (
            <div>
                {' '}
                {children}
            </div>
        );
    }
}*/
// const enhance = compose(
//     withRouter
// );
// export default enhance(AppMenu);
export default AppMenu;
