var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
var useStyles = makeStyles(function (theme) { return ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    }
}); });
var SubMenu = function (_a) {
    var handleToggle = _a.handleToggle, isOpen = _a.isOpen, sidebarIsOpen = _a.sidebarIsOpen, name = _a.name, icon = _a.icon, dense = _a.dense, children = _a.children;
    var translate = useTranslate();
    var classes = useStyles();
    var header = (_jsxs(MenuItem, __assign({ dense: dense, button: true, onClick: handleToggle }, { children: [_jsx(ListItemIcon, __assign({ className: classes.icon }, { children: isOpen ? _jsx(ExpandMore, {}, void 0) : icon }), void 0), _jsx(Typography, __assign({ variant: "body1", color: "textSecondary" }, { children: translate(name) }), void 0)] }), void 0));
    return (_jsxs(Fragment, { children: [sidebarIsOpen || isOpen ? (header) : (_jsx(Tooltip, __assign({ title: translate(name), placement: "right" }, { children: header }), void 0)), _jsxs(Collapse, __assign({ in: isOpen, timeout: "auto", unmountOnExit: true }, { children: [_jsx(List, __assign({ dense: dense, component: "div", disablePadding: true, className: sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed }, { children: children }), void 0), _jsx(Divider, {}, void 0)] }), void 0)] }, void 0));
};
export default SubMenu;
