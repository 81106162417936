import { useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { Card, Avatar, createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import { LoginForm } from "react-admin";
import classnames from "classnames";
import { defaultLightTheme, defaultDarkTheme } from "@bedrock/ra-core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "./Logo";
import { Notification } from "react-admin";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    avatar: {
      margin: "2em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      //backgroundColor: theme.palette.secondary[500],
      backgroundColor: "transparent",
      width: "100px",
      height: "100px",
      borderRadius: 0,
    },
    appBar: {
      backgroundColor: "#f0ba46",
      color: "#000099",
      fontWeight: 800,
      alignItems: "center",
    },
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    loginMessage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotPassword: {
      textAlign: "right",
      margin: "15px",
    },
  }),
  { name: "RaLogin" }
);
const stopPropagation = (e) => e.stopPropagation();

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider} disableTelemetry>
 *             ...
 *        </Admin>
 *     );
 */
const AppLoginPage = ({
  classes: classesOverride,
  className,
  children,
  staticContext,
  ...rest
}) => {
  const theme = defaultLightTheme;
  const backgroundImage = "Dashboard_cover.jpeg";
  const containerRef = useRef();
  const classes = useStyles({ classes: classesOverride });
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <AppBar className={classes.appBar}>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />

        <Typography className={classes.boldText} variant="h4">
          {process.env.REACT_APP_HEADER_LABEL}
        </Typography>

        <span className={classes.spacer} />
      </AppBar>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
      >
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <Logo style={{ width: "80px", height: "80px" }} />
            </Avatar>
          </div>

          <Typography className={classes.loginMessage} variant="h7">
            Please login with your credentials
          </Typography>
          <LoginForm />
          <div className={classes.forgotPassword}>
            <Button
              style={{
                color: "red",
                cursor: "pointer",
              }}
              component={Link}
              to={"/auth/forgot-password"}
              // onClick={stopPropagation}
            >
              Forgot Password ?
            </Button>
          </div>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

/* export default connect(
    state => ({
        theme: state.theme === 'dark' ? defaultDarkTheme : defaultLightTheme,
    }),
    {}
)(AppLoginPage);
 */
export { AppLoginPage };
