var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-final-form';
import { useDataProvider, DatagridLoading, useUpdate, useRedirect, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PermissionsGroupCollection from './PermissionsGroupCollection';
import { useFormState } from 'react-final-form';
import { useRefresh } from 'ra-core';
var useStyles = makeStyles(function (theme) { return ({
    heading: {
        marginTop: '10px',
        fontSize: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular
    },
    saveButton: {
        marginLeft: '12px'
    }
}); });
var RolePermissionsManagerInput = function (_a) {
    var record = _a.record, roleId = _a.roleId, isFirstRender = _a.isFirstRender, setInitialRender = _a.setInitialRender, props = _a.props;
    var formState = useFormState();
    var refresh = useRefresh();
    var _b = useUpdate('RoleList', roleId), update = _b[0], loading = _b[1].loading;
    var notify = useNotify();
    var redirectTo = useRedirect();
    var basePath = props.basePath, redirect = props.redirect;
    var handleClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleSave(formState.values)];
                case 1:
                    _a.sent();
                    refresh();
                    return [2 /*return*/];
            }
        });
    }); }, [formState]);
    var handleSave = useCallback(function (values, redirect) {
        update({
            payload: { data: __assign(__assign({}, values), { average_note: 10 }) }
        }, {
            onSuccess: function (_a) {
                var newRecord = _a.data;
                notify('ra.notification.created', 'info', {
                    smart_count: 1
                });
                redirectTo(redirect, basePath, newRecord.id, newRecord);
            }
        });
    }, [update, notify, redirectTo, basePath, useFormState, redirect]);
    var classes = useStyles();
    var form = useForm();
    var dataProvider = useDataProvider();
    var _c = useState([]), permissionGroups = _c[0], setPermissionGroups = _c[1];
    var _d = useState([]), recordPermissionsIds = _d[0], setRecordPermissionsIds = _d[1];
    var _e = useState([]), permissionsArray = _e[0], setPermissionsIdArray = _e[1];
    var _f = useState([]), newlyAddedMultiplePermissions = _f[0], setNewlyAddedMultiplePermissions = _f[1];
    var _g = useState([]), filterdPermissionsIds = _g[0], setFilterdPermissionsIds = _g[1];
    var _h = useState([]), recordPermissions = _h[0], setRecordPermissions = _h[1];
    var toUpdatePermissionsIds = [];
    var toUpdatePermissions = [];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var preparedPermissionGroups;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetchAndPrepareAccessData(record, roleId)];
                        case 1:
                            preparedPermissionGroups = _a.sent();
                            setPermissionGroups(preparedPermissionGroups);
                            if (record.permissionsIds) {
                                setRecordPermissionsIds(record.permissionsIds);
                                setFilterdPermissionsIds(record.permissionsIds);
                                setRecordPermissions(record.permissions);
                            }
                            if (isFirstRender) {
                                refresh();
                                setInitialRender(false);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, [roleId + "-" + (permissionGroups ? permissionGroups.length : 0)]);
    var fetchAndPrepareAccessData = function (record, roleId) { return __awaiter(void 0, void 0, void 0, function () {
        var permissionGroupsPayload, permissionGroupData, permissionGroupsList, permissionsIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    permissionGroupsPayload = {
                        filter: {},
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' }
                    };
                    return [4 /*yield*/, dataProvider('GET_LIST', 'PermissionGroup', permissionGroupsPayload)];
                case 1:
                    permissionGroupData = _a.sent();
                    permissionGroupsList = permissionGroupData.data;
                    permissionsIds = record.permissionsIds ? record.permissionsIds : [];
                    return [2 /*return*/, prepareData(permissionGroupsList, permissionsIds)];
            }
        });
    }); };
    var assignPermissionGroupAccess = function (permissionsIdsList, permissionGroup) {
        var allPermissionsChecked = true;
        for (var permissionIndex = 0; permissionIndex < permissionGroup.permissions.length; permissionIndex++) {
            var permission = permissionGroup.permissions[permissionIndex];
            var permissionId = permission.id;
            var isPermissionAssigned = permissionsIdsList.includes(permissionId);
            Object.assign(permission, {
                isChecked: isPermissionAssigned
            });
            if (!isPermissionAssigned) {
                allPermissionsChecked = false;
            }
        }
        if (permissionGroup.childPermissionGroups) {
            for (var childPermissionGroupIndex = 0; childPermissionGroupIndex <
                permissionGroup.childPermissionGroups.length; childPermissionGroupIndex++) {
                var childPermissionGroup = permissionGroup.childPermissionGroups[childPermissionGroupIndex];
                var areAllChildPermissionGroupPermissionsChecked = assignPermissionGroupAccess(permissionsIdsList, childPermissionGroup);
                if (!areAllChildPermissionGroupPermissionsChecked) {
                    allPermissionsChecked = false;
                }
            }
        }
        Object.assign(permissionGroup, {
            isChecked: allPermissionsChecked
        });
        return allPermissionsChecked;
    };
    var listToTree = function (list) {
        var map = {};
        var node;
        var roots = [];
        var i;
        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentPermissionGroup && node.parentPermissionGroup.id) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.parentPermissionGroup.id]].children.push(node);
            }
            else {
                roots.push(node);
            }
        }
        return roots;
    };
    var prepareData = function (allPermissionGroups, rolePermissionIds) {
        for (var permissionGroupIndex = 0; permissionGroupIndex < allPermissionGroups.length; permissionGroupIndex++) {
            var permissionGroup = allPermissionGroups[permissionGroupIndex];
            assignPermissionGroupAccess(rolePermissionIds, permissionGroup);
        }
        var sortedUnorderedPermissionGroupsWithPermissions = allPermissionGroups.sort(function (a, b) {
            var aParentPermissiondGroupId = a && a.parentPermissionGroup ? a.parentPermissionGroup.id : null;
            var bParentPermissiondGroupId = b && b.parentPermissionGroup ? b.parentPermissionGroup.id : null;
            return aParentPermissiondGroupId - bParentPermissiondGroupId;
        });
        var unsortedPermissionGroupsWithPermissions = listToTree(sortedUnorderedPermissionGroupsWithPermissions);
        var permissionGroupsWithPermissions = unsortedPermissionGroupsWithPermissions.sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
        });
        return permissionGroupsWithPermissions;
    };
    var addOrRemoveSinglePermission = function (permission, isAdd) {
        var permissionId = permission.id;
        if (isAdd) {
            if (!recordPermissionsIds.includes(permissionId)) {
                // enter when id not in record
                console.log(recordPermissions);
                recordPermissions.push({ id: permissionId });
                recordPermissionsIds.push(permissionId);
            }
        }
        else if (!isAdd) {
            if (recordPermissionsIds.includes(permissionId)) {
                var permissionIdIndex = recordPermissionsIds.indexOf(permissionId);
                if (permissionIdIndex > -1) {
                    recordPermissionsIds.splice(permissionIdIndex, 1);
                }
                var permissionsIndex = recordPermissions.findIndex(function (permission) { return permission.id === permissionId; });
                if (permissionsIndex > -1) {
                    recordPermissions.splice(permissionsIndex, 1);
                }
            }
        }
        form.change('permissionsIds', recordPermissionsIds);
        form.change('permissions', recordPermissions);
    };
    var removeSinglePermission = function (permission) {
        var permissionId = permission.id;
        if (recordPermissionsIds.includes(permissionId)) {
            var permissionIdIndex = filterdPermissionsIds.indexOf(permissionId);
            if (permissionIdIndex > -1) {
                filterdPermissionsIds.splice(permissionIdIndex, 1);
            }
            var permissionsIndex = recordPermissions.findIndex(function (permission) { return permission.id === permissionId; });
            if (permissionsIndex > -1) {
                recordPermissions.splice(permissionsIndex, 1);
            }
            form.change('permissionsIds', filterdPermissionsIds);
            form.change('permissions', recordPermissions);
        }
    };
    var addOrRemoveMultiplePermissions = function (newlyAddedMultiplePermissionsList, isAdd) {
        if (isAdd) {
            var newPermissionsList = newlyAddedMultiplePermissionsList.filter(function (addedPermission) { return !recordPermissionsIds.includes(addedPermission.id); });
            console.log(newPermissionsList);
            newPermissionsList.map(function (permissions) {
                recordPermissions.push({ id: permissions.id });
                recordPermissionsIds.push(permissions.id);
            });
            // console.log(newlyAddedMultiplePermissions);
            // newPermissionsList.map((permission) =>
            //   recordPermissionsIds.push(permission.id)
            // );
            // toUpdatePermissionsIds = [
            //   ...recordPermissionsIds,
            //   ...newPermissionIdsList,
            // ];
            // toUpdatePermissions = [
            //   ...record.permissions,
            //   ...newlyAddedMultiplePermissions,
            // ];
            // console.log(recordPermissionsIds, recordPermissions);
        }
        else if (!isAdd) {
            var toRemovPermissionIdsList = newlyAddedMultiplePermissionsList.map(function (toRemovePermission) { return toRemovePermission.id; });
            toRemovPermissionIdsList.map(function (toPrmovePermissionId) {
                var permissionIdIndex = recordPermissionsIds.indexOf(toPrmovePermissionId);
                if (permissionIdIndex > -1) {
                    return recordPermissionsIds.splice(permissionIdIndex, 1);
                }
            });
            toRemovPermissionIdsList.map(function (toPrmovePermissionId) {
                var permissionIdIndex = recordPermissions.findIndex(function (x) { return x.id === toPrmovePermissionId; });
                if (permissionIdIndex > -1) {
                    return recordPermissions.splice(permissionIdIndex, 1);
                }
            });
        }
        form.change('permissionsIds', recordPermissionsIds);
        form.change('permissions', recordPermissions);
    };
    if (permissionGroups && permissionGroups.length > 0 && !isFirstRender) {
        return (_jsx(_Fragment, { children: _jsx(PermissionsGroupCollection, { initialPermissionGroups: permissionGroups, addOrRemoveSinglePermission: addOrRemoveSinglePermission, addOrRemoveMultiplePermissions: addOrRemoveMultiplePermissions, props: props }, void 0) }, void 0));
    }
    else {
        return (_jsx(DatagridLoading, { classes: classes, expand: false, hasBulkActions: false, nbChildren: 15 }, void 0));
    }
};
export default RolePermissionsManagerInput;
