var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField, BooleanField } from 'react-admin';
import { PERM_PERMISSION_GROUP_EDIT } from '../../constants/permissions';
import DefaultList from '../../../../components/list/defaultList/DefaultList';
import DefaultGatedListActions from '../../../../components/list/actions/DefaultGatedListActions';
var allowedPermissionNames = {
    edit: PERM_PERMISSION_GROUP_EDIT
};
var PermissionGroupList = function (_a) {
    var permissions = _a.permissions, props = __rest(_a, ["permissions"]);
    return (_jsxs(DefaultList, __assign({}, props, { sort: { field: 'sort_order', order: 'ASC' }, bulkActionButtons: false, permissions: permissions, actions: _jsx(DefaultGatedListActions, { allowedPermissionNames: allowedPermissionNames }, void 0) }, { children: [_jsx(TextField, { source: "name" }, void 0), _jsx(TextField, { source: "label" }, void 0), _jsx(TextField, { source: "description" }, void 0), _jsx(BooleanField, { source: "isActive", sortBy: "is_active" }, void 0)] }), void 0));
};
export default PermissionGroupList;
