import gql from "graphql-tag";

const formStatsCustomQueries = (builtQuery, fetchType, resource, params) => {
  if (resource === "FormStats") {
    if (fetchType === "GET_LIST") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query allFormStats(
            $filter: ListFormStatsFilterInput!
            $page: Int!
            $perPage: Int!
            $sortField: String
            $sortOrder: String
          ) {
            items: allFormStats(
              filter: $filter
              page: $page
              perPage: $perPage
              sortField: $sortField
              sortOrder: $sortOrder
            ) {
              id
              monthName
              formName
              hardDeleted
              softDeleted
              active
              total
            }
            total: _allFormStatsMeta(
              filter: $filter
              page: $page
              perPage: $perPage
            ) {
              count
              __typename
            }
          }
        `,
      };
    }
  }
  return builtQuery;
};

export { formStatsCustomQueries };
