var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { usePermissions } from 'react-admin';
import { can } from '../../helpers/auth';
import SubMenu from './SubMenu';
var GatedSubMenu = function (_a) {
    var menuName = _a.menuName, icon = _a.icon, open = _a.open, allowedPermissionName = _a.allowedPermissionName, dense = _a.dense, children = _a.children;
    var _b = useState(false), state = _b[0], setState = _b[1];
    var handleToggle = function () {
        setState(!state);
    };
    var _c = usePermissions(), loaded = _c.loaded, permissions = _c.permissions;
    var renderSubMenu = function (permissions) { return (_jsx(Fragment, { children: can(permissions, allowedPermissionName) && (_jsx(SubMenu, __assign({ handleToggle: function () { return handleToggle(state); }, isOpen: state, sidebarIsOpen: open, name: menuName, icon: icon, dense: dense }, { children: children }), void 0)) }, void 0)); };
    return renderSubMenu(permissions);
};
export default GatedSubMenu;
