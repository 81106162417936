import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const fileName = "branches";

export const branchExporter = (branches) => {
  const branchdata = branches.map((branch) => {
    const {
      id,
      name,
      notificationEmail,
    } = branch;

    const exportEntry = {
      Id: id,
      "Branch Name": name,
      "Notification Email": notificationEmail,
    };
    return exportEntry;
  });

  jsonExport(
    branchdata,
    {
      headers: [
        "Id",
        "Branch Name",
        "Notification Email",
      ],
      rowDelimiter: ",",
      forceTextDelimiter: true,
    },
    (err, csv) => {
      downloadCSV(csv, fileName);
    }
  );
};
