import { useShowController } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DefaultShow from "@bedrock/ra-core/dist/esm/components/show/defaultShow/DefaultShow";
import { DefaultCrudToolbar } from "@bedrock/ra-core";
import CustomHeader from "../../../../../layout/CustomHeader";

import {
  PMJJBYShowFormDataComponent,
  FormEntryShowViewToolbarButton,
  PERM_FORM_ENTRIES_LIST_EDIT,
  FormPageTitle,
  defaultShow,
} from "@smartforms/commonui";

const useStyles = makeStyles(defaultShow);

const PMJJBYFormShow = (props) => {
  const classes = useStyles();
  const controllerProps = useShowController(props);

  return (
    <div>
      <CustomHeader />
      <DefaultShow
        {...props}
        {...controllerProps}
        actions={<DefaultCrudToolbar />}
        title={
          <FormPageTitle
            titleName="resources.PMJJBYForm.name"
            titleFieldValue="name"
          />
        }
      >
        <PMJJBYShowFormDataComponent
          classes={classes}
          {...controllerProps}
          isFront={false}
        />
        <FormEntryShowViewToolbarButton
          allowedPermissionNames={{ edit: PERM_FORM_ENTRIES_LIST_EDIT }}
        />
      </DefaultShow>
    </div>
  );
};

export { PMJJBYFormShow };
