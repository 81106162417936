var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useUpdate, useRedirect, useNotify } from 'react-admin';
import { saveButton } from './Style';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(saveButton);
var SaveRoleButton = function (_a) {
    var handleSubmitWithRedirect = _a.handleSubmitWithRedirect, record = _a.record, props = __rest(_a, ["handleSubmitWithRedirect", "record"]);
    var _b = useUpdate('Role', record.id), update = _b[0], loading = _b[1].loading;
    var redirectTo = useRedirect();
    var notify = useNotify();
    var basePath = props.basePath, redirect = props.redirect;
    // get values from the form
    var formState = useFormState();
    var handleClick = useCallback(function () {
        // call dataProvider.create() manually
        var _a = formState.values, id = _a.id, isActive = _a.isActive, createdAt = _a.createdAt, version = _a.version, name = _a.name, description = _a.description, permissions = _a.permissions, permissionsIds = _a.permissionsIds, users = _a.users, usersIds = _a.usersIds;
        var data = formState.values;
        console.log(formState.values);
        var updatedValues = {
            id: id,
            isActive: isActive,
            createdAt: createdAt,
            version: version,
            name: name,
            description: description,
            permissions: permissions,
            permissionsIds: permissionsIds,
            users: users,
            usersIds: usersIds
        };
        update({
            payload: {
                id: record.id,
                data: __assign({}, updatedValues)
            }
        }, {
            onSuccess: function (_a) {
                var newRecord = _a.data;
                redirectTo('/Role');
                notify('resources.Role.notification.updated', 'info', {
                    smart_count: 1
                });
            },
            onFailure: function (error) { return notify("Error: " + error.message, 'warning'); }
        });
    }, [update, notify, redirectTo, basePath, formState, redirect]);
    var classes = useStyles();
    return (_jsx("div", { children: _jsx(SaveButton, __assign({}, props, { disabled: loading, handleSubmitWithRedirect: handleClick }), void 0) }, void 0));
};
export { SaveRoleButton };
