import { connect } from "react-redux";
import { Layout, Sidebar } from "react-admin";
import AppBar from "./AppBar";
import AppMenu from "./AppMenu";
import { defaultLightTheme, defaultDarkTheme } from "@bedrock/ra-core";

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;
const CustomLayout = (props) => (
  <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={AppMenu} />
);

export default connect(
  (state) => ({
    theme: state.theme === "dark" ? defaultDarkTheme : defaultLightTheme,
  }),
  {}
)(CustomLayout);
