var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import users from '../entities/users';
import roles from '../entities/roles';
import permissionGroups from '../entities/permissionGroups';
import permissionsEntity from '../entities/permissions';
import GatedSubMenu from '../../../components/menu/GatedSubMenu';
import GatedMenuItemLink from '../../../components/menu/GatedMenuItemLink';
import { PERM_ACCESS_MENU, PERM_USER_MENU, PERM_ROLE_MENU, PERM_PERMISSION_GROUP_MENU, PERM_PERMISSION_MENU } from '../constants/permissions';
var AccessMenu = function (_a) {
    var onMenuClick = _a.onMenuClick, dense = _a.dense, open = _a.open;
    return (_jsx(Fragment, { children: _jsxs(GatedSubMenu, __assign({ menuName: "resources.accessMenu", icon: _jsx(users.icon, {}, void 0), open: open, allowedPermissionName: PERM_ACCESS_MENU, dense: dense }, { children: [_jsx(GatedMenuItemLink, { to: "/User", menuName: "resources.User.menu", leftIcon: _jsx(users.icon, {}, void 0), onClick: onMenuClick, allowedPermissionName: PERM_USER_MENU, open: open, dense: dense }, void 0), _jsx(GatedMenuItemLink, { to: "/Role", menuName: "resources.Role.menu", leftIcon: _jsx(roles.icon, {}, void 0), onClick: onMenuClick, allowedPermissionName: PERM_ROLE_MENU, open: open, dense: dense }, void 0), _jsx(GatedMenuItemLink, { to: "/PermissionGroup", menuName: "resources.PermissionGroup.menu", leftIcon: _jsx(permissionGroups.icon, {}, void 0), onClick: onMenuClick, allowedPermissionName: PERM_PERMISSION_GROUP_MENU, open: open, dense: dense }, void 0), _jsx(GatedMenuItemLink, { to: "/Permission", menuName: "resources.Permission.menu", leftIcon: _jsx(permissionsEntity.icon, {}, void 0), onClick: onMenuClick, allowedPermissionName: PERM_PERMISSION_MENU, open: open, dense: dense }, void 0)] }), void 0) }, void 0));
};
export { AccessMenu };
