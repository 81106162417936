import { useState, useEffect } from "react";
import { useTranslate, useNotify } from "react-admin";
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/Refresh';
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DashboardTable from "../DashboardTable";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { CardWithIcon, BranchSelect } from '@smartforms/commonui';
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { usePermissions } from "react-admin";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertError from '@material-ui/icons/ErrorOutline';
import ActionCheck from '@material-ui/icons/CheckCircle';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import { EMAIL_PENDING_ENTRIES } from "@smartforms/commonui";


const useStyles = makeStyles((theme) => ({
  button: {
    height: "36px",
    marginLeft: "10px",
  },
  buttonLabel: {

    color: "rgb(0, 0, 153)",
  },
  iconPaddingStyle: {
    paddingRight: '0.2em'
  },
}));

const styles = {
  title: {
    color: "#000099",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: { width: "30%", paddingLeft: "10px", paddingBottom: "40px" },
  paddingTop: { paddingTop: "20px" },
  flex: { display: "flex" },
  flexRow: { display: "flex", flexDirection: "row" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  iconPaddingStyle: {
    // paddingRight: '0.2em'
  },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ padding: "5em" }} />;

const FormSummaryStatsCard = ({
  allBranchesOptions,
  totalFormEntriesCount,
  summaryStatusCount,
  formsSummary,
  refetch,
  defaultBranchOption,
  handleBranchIdChanged,
  branchId,
  totalMediaSize
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const translate = useTranslate();
  const totalReceivedCount = summaryStatusCount?.received;
  const totalProcessedCount = summaryStatusCount?.processed;
  const [isOpen, setOpen] = useState(false);
  const [mailSendNotification, setMailSendNotification] = useState(false);

  const { permissions } = usePermissions();
  const allowSendPendingEntriesEmailPermission = hasPermission(
    permissions,
    EMAIL_PENDING_ENTRIES
  );

  const [handleSubmit, { loading, data, error }] = useLazyQuery(
    emailPendingEntries,
    {
      displayName: "DashboardQuery",
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      notify(`${translate("dashboard.formSummaryStats.successMessage")}`);
      refetch();
    }
  }, [data]);

  if (error) {
    console.log(error)
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    refetch();
  }

  const sendPendingEntries = (e) => {
    e.preventDefault()
    setOpen(false);
    handleSubmit({ variables: { branchId: parseInt(branchId) } })
  }

  return (
    <div>
      <h1 style={styles.title}>Form Entries</h1>
      <div style={styles.flex}>
        <div style={styles.input}>
          <BranchSelect
            name={"branch"}
            styles={styles.input}
            placeholder={translate("components.app.branch.placeholder")}
            onChange={handleBranchIdChanged}
            allBranchesOptions={allBranchesOptions}
            defaultBranchOption={defaultBranchOption}
            isClearable
            isSearchable
          />
        </div>

        {allowSendPendingEntriesEmailPermission &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
            onClick={e => handleClick()}
          >
            <span className={classes.buttonLabel}>
              {translate("dashboard.formSummaryStats.emailPendingEntries")}
            </span>
          </Button>
        }

        <IconButton
          color="primary"
          aria-label="Refresh Data"
          title="Refresh Data"
          className={classes.button}
          onClick={e => handleRefresh()}
        >
          <RefreshIcon />
        </IconButton>
      </div>

      <div style={styles.flex}>
        <CardWithIcon
          to="/Form"
          icon={DonutLargeIcon}
          title={translate("dashboard.formSummaryStats.totalEntries")}
          subtitle={totalFormEntriesCount ? totalFormEntriesCount : '0'}
        />
        <Spacer />
        <CardWithIcon
          to="/Form"
          icon={PlayForWorkIcon}
          title={translate("dashboard.formSummaryStats.receivedEntries")}
          subtitle={totalReceivedCount ? totalReceivedCount : "0"}
        />
      </div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <CardWithIcon
          to="/Form"
          icon={CheckCircleIcon}
          title={translate("dashboard.formSummaryStats.processedEntries")}
          subtitle={totalProcessedCount ? totalProcessedCount : "0"}
        />
        <Spacer />
        <CardWithIcon
          to="/Form"
          icon={ListAltRoundedIcon}
          title={translate("dashboard.formSummaryStats.totalMediaSize")}
          subtitle={totalMediaSize ? totalMediaSize : "0"}
        />
      </div>
      <div style={styles.paddingTop}>
        <DashboardTable formsSummary={formsSummary} />
      </div>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("dashboard.formSummaryStats.emailPendingEntries")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.contentText}>
            {translate("dashboard.formSummaryStats.emailPendingEntriesDialogMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            <AlertError className={classes.iconPaddingStyle} />
            {translate("dashboard.formSummaryStats.cancelLabel")}
          </Button>
          <Button
            disabled={loading}
            // onClick={(e) => { handleSubmit({ variables: { branchId: parseInt(branchId) } }) }}
            onClick={(e) => { sendPendingEntries(e) }}
            color="primary"
            autoFocus
          >
            <ActionCheck className={classes.iconPaddingStyle} />
            {translate("dashboard.formSummaryStats.okLabel")}
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
};

FormSummaryStatsCard.defaultProps = {
  defaultValue: undefined,
};

const emailPendingEntries = gql`
  query EmailPendingEntries($branchId: Int) {
    data: EmailPendingEntries(branchId: $branchId) {
      branchId
    }
  }
`;

export { FormSummaryStatsCard };
