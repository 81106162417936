export const styles = (theme) => ({
  floatLeft: {
    float: "left",
  },
  floatLeftWithMarginLeft: {
    float: "left",
    [theme.breakpoints.up("xs")]: {
      marginLeft: 32,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  clearWithFloatLeft: {
    float: "left",
    clear: "left",
  },
  width544: { width: 544 },
  maxWidth544: { maxWidth: 544 },
  orderNotFound: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: "400",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: "1.46429em",
    margin: "1.33em 0 1.33em 0",
  },
  paddingLeft12: {
    paddingLeft: 12,
  },
  marginTopBottom: {
    m: 160,
  },
  datePicker: {
    marginTop: 10,
    marginBottom: 10,
    padding: "0 !important",
    minWidth: "0 !important",
  },
});

export const show = (theme) => ({
  inlineGridWith50PercentWidth: {
    [theme.breakpoints.up("xs")]: {
      display: "inline-grid",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  orderNotFound: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: "400",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: "1.46429em",
    margin: "1.33em 0 1.33em 0",
  },
  remarks: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
