import { useTranslate, TextInput, ReferenceArrayInput, SelectArrayInput, AutocompleteArrayInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { DefaultCrudToolbar, EditViewToolbarButton } from "@bedrock/ra-core";
import DefaultEdit from "@bedrock/ra-core/dist/esm/components/edit/defaultEdit/DefaultEdit";
import { validations } from "./validations";
import { styles } from "./styles";

const Title = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Branch.name", { smart_count: 1 })}
      {record && record.name ? `"${record.name}"` : ""}
    </span>
  );
};

const useStyles = makeStyles(styles);

const BranchEdit = (props) => {
  const classes = useStyles();

  return (
    <DefaultEdit
      actions={<DefaultCrudToolbar />}
      title={<Title />}
      toolbar={
        <EditViewToolbarButton allowedPermissionNames={{ delete: false }} />
      }
      {...props}
    >
      <TextInput
        source="name"
        formClassName={classes.width544}
        validate={validations.name}
      />
      <TextInput
        source="address"
        formClassName={classes.width544}
        validate={validations.address}
      />
      
      <TextInput
        source="notificationEmail"
        formClassName={classes.clearWithFloatLeft}
        validate={validations.notificationEmail}
      />
      <TextInput
        source="description"
        multiline
        formClassName={classes.width544}
        validate={validations.description}
      />

      <ReferenceArrayInput
        source="usersIds"
        reference="User"
        sort={{ field: 'first_name', order: 'ASC' }}
      >
        <AutocompleteArrayInput 
          translateChoice={false}
          allowDuplicates={false}
          allowEmpty={false}
          optionText="fullName"
        />
      </ReferenceArrayInput>


    </DefaultEdit>
  );
};

export { BranchEdit };