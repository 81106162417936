import { matchPath } from 'react-router';
var isPublicUrl = function () {
    var isMatchingResetPath = !!matchPath(window === null || window === void 0 ? void 0 : window.location.hash, '#/auth/reset-password/');
    var resetPasswordPath = isMatchingResetPath && (window === null || window === void 0 ? void 0 : window.location.hash);
    var currentLocation = window === null || window === void 0 ? void 0 : window.location.hash;
    var publicUrlPathsList = ['#/auth/forgot-password', "" + resetPasswordPath];
    return publicUrlPathsList.includes(currentLocation);
};
var defaultApolloGqlAuthProvider = {
    login: function (params) {
        var username = params.username, password = params.password;
        var query = JSON.stringify({
            query: "mutation {\n              login(\n                    email: \"" + username + "\"\n                    password: \"" + password + "\"\n                ) \n                { \n                    token\n                    user {\n                      id\n                      firstName\n                      lastName\n                      email\n                      version\n                      settings{\n                        theme\n                        locale\n                      }\n                      permissions{\n                        name\n                      }\n                      roles{\n                        name\n                      }\n                    }\n                }\n            }\n    "
        });
        var request = new Request(process.env.REACT_APP_SERVER_ENDPOINT + "/graphql", {
            method: 'POST',
            body: query,
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(function (response) {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return response.json();
        })
            .then(function (response) {
            var data = response.data;
            if (!data && response.errors) {
                var error = response.errors[0];
                var errorMessage = error.message;
                throw new Error(errorMessage);
            }
            else {
                var jwt_token = data.login.token;
                var user = data.login.user;
                var userId = user.id;
                var permissionsList = user.permissions.map(function (permission) { return permission.name; });
                // const permissionsList = data.login.permissionsList;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('jwt_token', jwt_token);
                localStorage.setItem('userId', userId);
                localStorage.setItem('permissionsList', JSON.stringify(permissionsList));
            }
        });
        return Promise.resolve();
    },
    logout: function (params) {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('permissionsList');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkAuth: function (params) {
        if (isPublicUrl()) {
            return Promise.resolve();
        }
        return localStorage.getItem('jwt_token')
            ? Promise.resolve()
            : Promise.reject();
    },
    checkError: function (error) {
        console.log("checkError: " + JSON.stringify(error));
        var status = -1;
        if (error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].message &&
            error.graphQLErrors[0].message.statusCode) {
            status = error.graphQLErrors[0].message.statusCode;
        }
        //const status = params?.graphQLErrors?.[0]?.message?.statusCode;
        if (status === 401 || status === 403) {
            localStorage.removeItem('jwt_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: function (params) {
        if (isPublicUrl()) {
            return Promise.resolve();
        }
        var permissionsListStr = localStorage.getItem('permissionsList');
        var permissions = JSON.parse(permissionsListStr);
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
};
/*
const defaultApolloGqlAuthProvider = (type, params) => {

    console.log(`defaultApolloGqlAuthProvider: ${type} - ${JSON.stringify(params)}`);

    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const query = JSON.stringify({
            query: `mutation {
              login(
                    email: "${username}"
                    password: "${password}"
                )
                {
                    token
                    user {
                      id
                      firstName
                      lastName
                      email
                      version
                      settings{
                        theme
                        locale
                      }
                      permissions{
                        name
                      }
                      roles{
                        name
                      }
                    }
                }
            }
    `
        });

        const request = new Request( `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`, {
            method: 'POST',
            body: query,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.error);
                }
                return response.json();
            })
            .then((response) => {
                const data = response.data;
                if(!data && response.errors){
                    const error = response.errors[0];
                    const errorMessage = error.message;
                    throw new Error(errorMessage);
                }else{
                    const jwt_token = data.login.token;
                    const user = data.login.user;
                    const userId = user.id;
                    const permissionsList = user.permissions.map(permission => permission.name);;
                   // const permissionsList = data.login.permissionsList;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('jwt_token', jwt_token);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('permissionsList', JSON.stringify(permissionsList));
                }
            });

        return Promise.resolve();
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('permissionsList');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        let status = -1;
        if(params && params.graphQLErrors && params.graphQLErrors[0] && params.graphQLErrors[0].message && params.graphQLErrors[0].message.statusCode){
            status = params.graphQLErrors[0].message.statusCode;
        }
        //const status = params?.graphQLErrors?.[0]?.message?.statusCode;
        if (status === 401 || status === 403) {
            localStorage.removeItem('jwt_token');
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem('jwt_token')
            ? Promise.resolve()
            : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const permissionsListStr = localStorage.getItem('permissionsList');
        const permissions = JSON.parse(permissionsListStr);
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
    return Promise.reject('Unknown method');
};
*/
export default defaultApolloGqlAuthProvider;
