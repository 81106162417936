export var styles = {
    displayInlineBlock: {
        display: 'inline-block'
    },
    marginLeftDisplayInlineBlock: {
        marginLeft: '32px',
        display: 'inline-block'
    },
    width544: { width: 544 },
    maxWidth544: { maxWidth: 544 }
};
export var show = function (theme) {
    var _a;
    return ({
        inlineGridWith50PercentWidth: (_a = {},
            _a[theme.breakpoints.up('xs')] = {
                display: 'inline-grid',
                width: '50%'
            },
            _a[theme.breakpoints.down('xs')] = {
                width: '100%'
            },
            _a)
    });
};
