var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import ShowSinglePermission from './ShowSinglePermission';
var useStyles = makeStyles(function (theme) { return ({
    heading: {
        marginTop: '-10px',
        fontSize: theme.typography.pxToRem(15),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontStyle: 'italic'
    },
    gridList: {
        height: 'auto'
    },
    gridListTile: {
        root: {
            height: 'auto'
        }
    }
}); });
var ShowPermissionsGridListView = function (_a) {
    var permissionsList = _a.permissionsList, restProps = __rest(_a, ["permissionsList"]);
    var classes = useStyles();
    var _b = useState(permissionsList), currentPermissionsList = _b[0], setCurrentPermissionsList = _b[1];
    useEffect(function () {
        setCurrentPermissionsList(permissionsList);
    }, [permissionsList]);
    var getGridListCols = function () {
        if (isWidthUp('xl', restProps.width)) {
            return 4;
        }
        if (isWidthUp('lg', restProps.width)) {
            return 3;
        }
        if (isWidthUp('md', restProps.width)) {
            return 2;
        }
        if (isWidthUp('sm', restProps.width)) {
            return 2;
        }
        return 1;
    };
    if (currentPermissionsList) {
        return (_jsx("div", { children: _jsx(GridList, __assign({ cols: getGridListCols(), spacing: 6, cellHeight: "auto", className: classes.gridList }, { children: currentPermissionsList.map(function (permission) { return (_jsx(GridListTile, { children: _jsx(ShowSinglePermission, { permission: permission }, void 0) }, "gridList_" + permission.id)); }) }), void 0) }, void 0));
    }
    else {
        return (_jsx(Typography, __assign({ className: classes.heading }, { children: "No permissions are defined!" }), void 0));
    }
};
var enhance = compose(withWidth({ initialWidth: 'xl' }));
var ShowPermissionsGridList = enhance(ShowPermissionsGridListView);
export default ShowPermissionsGridList;
