import { TextInput } from "react-admin";
import { DefaultCrudToolbar } from "@bedrock/ra-core";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { validations } from "./validations";
import { DefaultCreate } from "@bedrock/ra-core";

const useStyles = makeStyles(styles);

const BranchCreate = (props) => {
  const classes = useStyles();

  return (
    <DefaultCreate
      actions={<DefaultCrudToolbar />}
      title="Create Customer"
      redirect="show"
      {...props}
      successMessage="resources.Branch.notification.created"
    >
      <TextInput
        source="name"
        multiline
        formClassName={classes.width544}
        validate={validations.name}
      />
      <TextInput
        source="address"
        multiline
        formClassName={classes.width544}
        validate={validations.address}
      />

      <TextInput
        type="email"
        source="notificationEmail"
        validation={{ email: true }}
        formClassName={classes.clearWithFloatLeft}
        validate={validations.notificationEmail}
      />
      <TextInput
        source="description"
        formClassName={classes.width544}
        validate={validations.description}
        multiline
      />
    </DefaultCreate>
  );
};

export default BranchCreate;
