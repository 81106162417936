// eslint-disable-next-line import/no-extraneous-dependencies
import { required, minLength, maxLength } from 'react-admin';
var nameRequiredValidationMessage = 'resources.Role.validations.name.required';
var descriptionRequiredValidationMessage = 'resources.Role.validations.description.required';
var name = [
    required(nameRequiredValidationMessage),
    minLength(2),
    maxLength(25)
];
var description = [
    required(descriptionRequiredValidationMessage),
    minLength(5),
    maxLength(50)
];
export var validateRoles = {
    name: name,
    description: description
};
export default validateRoles;
