import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { Link, useParams } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";

import { useTranslate } from "ra-core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { lightTheme } from "../../layout/themes";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(Dashboard_cover.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    textAlign: "center",
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
  },
  successText: {
    fontSize: "20px",
    fontWeight: "400",
    color: "green",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    margin: 0,
    marginTop: "20px",
  },
}));

const resetPassword = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    data: ResetPassword(password: $password, token: $token) {
      id
      isActive
      createdAt
      deletedAt
      version
      email
    }
  }
`;

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Typography className={classes.successText}>
        Your password has been changed successfully.
        <br />
        Please login with your changed password. <br />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={"/login"}
      >
        Login
      </Button>
    </div>
  );
};

const InputForm = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classes.hint}>Reset Password</div>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            name="password"
            // @ts-ignore
            component={renderInput}
            label="Password"
            type="password"
            disabled={loading}
          />
        </div>
        <div className={classes.input}>
          <Field
            name="confirmPassword"
            // @ts-ignore
            component={renderInput}
            label="Confirm Password"
            type="password"
            disabled={loading}
          />
        </div>
      </div>
      <CardActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
        >
          {loading && <CircularProgress size={25} thickness={2} />}
          Reset My Password
        </Button>

        <Button
          className={classes.button}
          style={{ marginLeft: "0px" }}
          variant="contained"
          color="primary"
          component={Link}
          to={"/auth/forgot-password"}
          fullWidth
        >
          Forgot Password
        </Button>
      </CardActions>
      {/* <Notification /> */}
    </form>
  );
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const DefaultResetPasswordComponent = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const [isResponse, setIsResponse] = useState(false);
  let { token } = useParams();
  const [open, isOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    isOpen(false);
  };

  const [callMutation, { data, error }] = useMutation(resetPassword, {
    variables: {
      password: password,
      token: token,
    },
  });

  useEffect(() => {
    if (data) {
      setLoading(false);
      setIsResponse(true);
      setSeverity("success");
      setMessage("Password Changed Successfully");
      isOpen(true);
    }
  }, [data, error]);

  const handleSubmit = async (values) => {
    // setLoading(true);
    if (values.password !== values.confirmPassword) {
      setSeverity("error");
      setMessage("Password Not Match");

      isOpen(true);
    } else {
      try {
        await callMutation();
      } catch (e) {
        setSeverity("error");
        setMessage("An error occured. Please request a new password.");
        isOpen(true);
      }
    }
  };

  const validate = (values) => {
    setPassword(values.password);
    setPassword(values.password);

    const errors = {};
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = translate("ra.validation.required");
    }

    return errors;
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            <div>
              {isResponse ? (
                <SuccessMessage />
              ) : (
                <InputForm
                  handleSubmit={handleSubmit}
                  loading={loading}
                  // setIsResponse={setIsResponse}
                />
              )}
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={severity}>
                  {message}
                </Alert>
              </Snackbar>
            </div>
          </Card>
        </div>
      )}
    />
  );
};

DefaultResetPasswordComponent.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in DefaultResetPassword won't get
// the right theme
const DefaultResetPassword = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <DefaultResetPasswordComponent {...props} />
  </ThemeProvider>
);

export default DefaultResetPassword;
