import { required, minLength, maxLength } from 'react-admin';
var validateFirstNameMessage = 'resources.User.validations.firstName.required';
var validateLastNameMessage = 'resources.User.validations.lastName.required';
var validateFirstName = [
    required(validateFirstNameMessage),
    minLength(2),
    maxLength(25)
];
var validateLastName = [
    required(validateLastNameMessage),
    minLength(2),
    maxLength(25)
];
var matchPassword = function (value, allValues) {
    return value !== allValues.password
        ? "This field must match with your password"
        : undefined;
};
var validate = {
    validateFirstName: validateFirstName,
    validateLastName: validateLastName,
    matchPassword: matchPassword
};
export default validate;
