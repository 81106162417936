import { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DefaultCrudToolbar, EditViewToolbarButton } from "@bedrock/ra-core";
import DefaultEdit from "@bedrock/ra-core/dist/esm/components/edit/defaultEdit/DefaultEdit";

import {
  defaultStyles,
  FastTagFormCreateEditContainer,
} from "@smartforms/commonui";

const useStyles = makeStyles(defaultStyles);

const FasTagFormEntryEdit = (props) => {
  const classes = useStyles();

  return (
    <DefaultEdit
      actions={<DefaultCrudToolbar />}
      toolbar={
        <EditViewToolbarButton allowedPermissionNames={{ delete: false }} />
      }
      {...props}
    >
      <FastTagFormCreateEditContainer
        isFront={false}
        {...props}
        classes={classes}
      />
    </DefaultEdit>
  );
};

export { FasTagFormEntryEdit };
