import Tooltip from "@material-ui/core/Tooltip";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import { usePermissions, useTranslate } from "react-admin";
import { PERM_FORM_ENTRIES_PDF_DOWNLOAD } from "../../constants";

const defaultServerUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}`;

const PDFDownloadButton = ({ record }) => {
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();
  const formEntryId = record?.id;
  const downloadUrl = `${defaultServerUrl}/form-entry/exports/pdf/${formEntryId}?token=${localStorage.getItem(
    "jwt_token"
  )}`;

  if (loaded && hasPermission(permissions, PERM_FORM_ENTRIES_PDF_DOWNLOAD)) {
    return (
      <Tooltip title={translate("components.app.buttons.pdf.tooltip")}>
        <a
          href={downloadUrl}
          className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary"
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            padding: "4px 5px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PictureAsPdfIcon style={{ paddingRight: "5px", fontSize: "20px" }} />
          {translate("components.app.buttons.pdf.label")}
        </a>
      </Tooltip>
    );
  }else{
    return null;
  }
};

export { PDFDownloadButton };
