var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import DefaultDropdownGatedButton from './DefaultDropdownGatedButton';
import DefaultGatedEditButton from './DefaultGatedEditButton';
var useStyles = makeStyles(function (theme) { return ({
    baseButtonStyle: {
        width: '100%',
        justifyContent: 'left',
        padding: '8px 16px'
    }
}); });
var DefaultDropdownGatedEditButton = function (_a) {
    var allowedPermissionName = _a.allowedPermissionName, hasEdit = _a.hasEdit, permissions = _a.permissions, gatedDropdownEditButtonProps = __rest(_a, ["allowedPermissionName", "hasEdit", "permissions"]);
    var classes = useStyles();
    return (_jsx(DefaultDropdownGatedButton, { children: _jsx(DefaultGatedEditButton, __assign({ allowedPermissionName: allowedPermissionName, hasEdit: hasEdit, permissions: permissions, classes: classes.baseButtonStyle }, gatedDropdownEditButtonProps), void 0) }, void 0));
};
export default DefaultDropdownGatedEditButton;
