import { EditButton, Toolbar, usePermissions, useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import BackButtonIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import { PDFDownloadButton } from "./PDFDownloadButton";

const FormEntryShowViewToolbarButton = ({
  allowedPermissionNames,
  showBackButton = true,
  ...props
}) => {
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();

  const BackButton = () => (
    <Button
      color="primary"
      component={Link}
      to={props.basePath}
      style={{ marginLeft: "5px", marginRight: "5px" }}
    >
      <BackButtonIcon style={{ paddingRight: "5px", fontSize: "20px" }} />
      {translate("resources.buttons.back")}
    </Button>
  );

  const CustomEditButton = () => {
    if (
      loaded &&
      allowedPermissionNames &&
      allowedPermissionNames.edit &&
      hasPermission(permissions, allowedPermissionNames.edit)
    ) {
      return (
        <div style={{ textAlign: "start" }}>
          <EditButton
            {...props}
            style={{ padding: "6px 16px", fontSize: "0.875rem" }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Toolbar {...props}>
      <CustomEditButton />
      <PDFDownloadButton />
      <BackButton />
    </Toolbar>
  );
};

export { FormEntryShowViewToolbarButton };
