import { useMemo } from "react";
import { TextField, usePermissions } from "react-admin";
import DefaultList from "@bedrock/ra-core/dist/esm/components/list/defaultList/DefaultList";
import { hasPermission } from "@bedrock/ra-core";
import { LinkToRelatedEntries } from "./LinkToRelatedEntries";
import DefaultDropdownListActionButton from "@bedrock/ra-core/dist/esm/components/list/listAction/DefaultDropdownListActionButton";
import FormListActions from "./FormListActions";
import {
  PERM_FORMS_EDIT,
  PERM_FORMS_SHOW,
  PERM_FORMS_CREATE,
  PERM_FORMS_DELETE,
} from "@smartforms/commonui";

const allowedPermissionNames = {
  edit: PERM_FORMS_EDIT,
  show: PERM_FORMS_SHOW,
  create: PERM_FORMS_CREATE,
  delete: PERM_FORMS_DELETE,
  export: PERM_FORMS_SHOW,
};

const hasButtonPermission = {
  create: true,
  edit: true,
  delete: true,
  show: true,
};

const FormList = (props) => {
  const { permissions } = usePermissions();

  const defaultFilterValues = useMemo(() => {
    const hasEditPermission = hasPermission(permissions, PERM_FORMS_SHOW);
    if (hasEditPermission) {
      return {};
    } else {
      return {
        isActive: true,
      };
    }
  }, [permissions]);

  return (
    <DefaultList
      {...props}
      perPage={100}
      sort={{ field: "sortorder", order: "ASC" }}
      actions={
        <FormListActions allowedPermissionNames={allowedPermissionNames} />
      }
      bulkActionButtons={false}
      filterDefaultValues={defaultFilterValues}
      filters={null}
    >
      <TextField source="name" sortBy="name" />
      <TextField source="description" sortBy="description" />
      <LinkToRelatedEntries
        source="slug"
        label="resources.Form.fields.entries"
        addLabel={false}
        sortable={false}
      />
      <DefaultDropdownListActionButton
        allowedPermissionNames={allowedPermissionNames}
        hasButtonPermission={hasButtonPermission}
      />
    </DefaultList>
  );
};

export default FormList;
