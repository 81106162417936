var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField, ReferenceInput, SearchInput, Filter, SelectInput } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import DefaultGatedListActions from '../../../../components/list/actions/DefaultGatedListActions';
import DefaultDropdownListActionButton from '../../../../components/list/listAction/DefaultDropdownListActionButton';
import { PERM_USER_ACTION_EXPORT, PERM_USER_CREATE, PERM_USER_EDIT, PERM_USER_SHOW, PERM_USER_DELETE } from '../../constants/permissions';
import DefaultList from '../../../../components/list/defaultList/DefaultList';
var allowedPermissionNames = {
    export: PERM_USER_ACTION_EXPORT,
    create: PERM_USER_CREATE,
    edit: PERM_USER_EDIT,
    show: PERM_USER_SHOW,
    delete: PERM_USER_DELETE
};
var hasButtonPermission = {
    edit: true,
    show: true,
    delete: true
};
var RolesField = function (_a) {
    var record = _a.record;
    return (_jsx("span", { children: record.roles.map(function (item) { return (_jsx(Chip, { style: { margin: '3px' }, label: item.name }, item)); }) }, void 0));
};
var DefaultFilters = function (props) {
    return (_jsxs(Filter, __assign({}, props, { children: [_jsx(SearchInput, { source: "q", alwaysOn: true }, void 0), _jsx(ReferenceInput, __assign({ source: "roleId", reference: "Role", label: "resources.User.fields.relationalFields.roles", perPage: 100, sort: { field: 'name', order: 'ASC' }, classes: {
                    'RaFilterFormInput-body-263': {
                        alignItems: 'center'
                    }
                } }, { children: _jsx(SelectInput, { optionText: "name" }, void 0) }), void 0)] }), void 0));
};
var UserList = function (_a) {
    var permissions = _a.permissions, record = _a.record, rest = __rest(_a, ["permissions", "record"]);
    return (_jsxs(DefaultList, __assign({}, rest, { sort: { field: 'first_name', order: 'ASC' }, bulkActionButtons: false, actions: _jsx(DefaultGatedListActions, { allowedPermissionNames: allowedPermissionNames }, void 0), permissions: permissions, filters: _jsx(DefaultFilters, {}, void 0) }, { children: [_jsx(TextField, { source: "firstName", sortBy: "first_name" }, void 0), _jsx(TextField, { source: "lastName", sortBy: "last_name" }, void 0), _jsx(TextField, { source: "email" }, void 0), _jsx(RolesField, { label: "resources.User.fields.relationalFields.roles" }, void 0), _jsx(DefaultDropdownListActionButton, { allowedPermissionNames: allowedPermissionNames, hasButtonPermission: hasButtonPermission }, void 0)] }), void 0));
};
export default UserList;
