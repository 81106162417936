import { DashboardMenuItem } from "react-admin";
import { useSelector } from "react-redux";
import {
  AccessMenu,
  DataSetupMenu,
  UtilitiesSetupMenu,
  AppMenu as CoreAppMenu,
} from "@bedrock/ra-core";
import FormsMenu from "./FormsMenu";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    fontSize: "1rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
}));

const AppMenu = ({ onMenuClick, dense }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const classes = useStyles();

  return (
    <CoreAppMenu>
      <DashboardMenuItem
        onClick={onMenuClick}
        sidebarIsOpen={open}
        className={classes.dashboard}
      />
      <FormsMenu onMenuClick={onMenuClick} dense={dense} open={open} />
      <AccessMenu onMenuClick={onMenuClick} dense={dense} open={open} />
      {/* <DataSetupMenu
                onMenuClick={onMenuClick}
                dense={dense}
                open={open}
            /> */}
      <UtilitiesSetupMenu onMenuClick={onMenuClick} dense={dense} open={open} />
    </CoreAppMenu>
  );
};

export default AppMenu;
