import { TextField, Filter } from "react-admin";
import DefaultGatedListActions from "@bedrock/ra-core/dist/esm/components/list/actions/DefaultGatedListActions";
import DefaultList from "@bedrock/ra-core/dist/esm/components/list/defaultList/DefaultList";

import { PERM_FORM_STATS_EXPORT } from "@smartforms/commonui";

const allowedPermissionNames = {
  export: PERM_FORM_STATS_EXPORT,
};

const FormStatsFilter = (props) => (
  <Filter {...props}>{/* <FormSelectDropdownInput/> */}</Filter>
);

const FormStatsList = (props) => {
  return (
    <DefaultList
      {...props}
      perPage="10"
      sort={{ field: "id", order: "ASC" }}
      bulkActionButtons={false}
      filters={<FormStatsFilter />}
      actions={
        <DefaultGatedListActions
          allowedPermissionNames={allowedPermissionNames}
        />
      }
    >
      <TextField source="monthName" sortable={false} />
      <TextField source="formName" sortable={false} />
      <TextField source="active" sortable={false} />
      <TextField source="softDeleted" sortable={false} />
      <TextField source="hardDeleted" sortable={false} />
      <TextField source="total" sortable={false} />
    </DefaultList>
  );
};

export { FormStatsList };
