var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Style';
import Button from '@material-ui/core/Button';
var useStyles = makeStyles(styles);
var ProfileActions = function (_a) {
    var _b = _a.href, href = _b === void 0 ? null : _b, _c = _a.buttonTitle, buttonTitle = _c === void 0 ? null : _c;
    var translate = useTranslate();
    var classes = useStyles();
    return (_jsx("div", __assign({ className: classes.actionButton }, { children: _jsx(Button, __assign({ color: "primary", href: href, variant: "contained" }, { children: translate(buttonTitle) }), void 0) }), void 0));
};
export default ProfileActions;
