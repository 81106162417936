"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERM_PERMISSION_DELETE = exports.PERM_PERMISSION_SHOW = exports.PERM_PERMISSION_EDIT = exports.PERM_PERMISSION_CREATE = exports.PERM_PERMISSION_LIST = exports.PERM_PERMISSION_MENU = exports.PERM_PERMISSION_GROUP_ACTION_EXPORT = exports.PERM_PERMISSION_GROUP_SHOW = exports.PERM_PERMISSION_GROUP_DELETE = exports.PERM_PERMISSION_GROUP_EDIT = exports.PERM_PERMISSION_GROUP_CREATE = exports.PERM_PERMISSION_GROUP_LIST = exports.PERM_PERMISSION_GROUP_MENU = exports.PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION = exports.PERM_ROLE_DELETE = exports.PERM_ROLE_SHOW = exports.PERM_ROLE_EDIT = exports.PERM_ROLE_CREATE = exports.PERM_ROLE_LIST = exports.PERM_ROLE_MENU = exports.PERM_USER_ACTION_EXPORT = exports.PERM_USER_DELETE = exports.PERM_USER_SHOW = exports.PERM_USER_EDIT = exports.PERM_USER_CREATE = exports.PERM_USER_LIST = exports.PERM_USER_MENU = exports.PERM_ACCESS_MENU = void 0;
exports.PERM_ACCESS_MENU = 'view-access-management-menu';
//Users
exports.PERM_USER_MENU = 'view-user-management-menu';
exports.PERM_USER_LIST = 'list-users';
exports.PERM_USER_CREATE = 'create-user';
exports.PERM_USER_EDIT = 'update-user';
exports.PERM_USER_SHOW = 'show-users';
exports.PERM_USER_DELETE = 'delete-user';
exports.PERM_USER_ACTION_EXPORT = 'export-user';
//Roles
exports.PERM_ROLE_MENU = 'view-role-management-menu';
exports.PERM_ROLE_LIST = 'list-roles';
exports.PERM_ROLE_CREATE = 'create-role';
exports.PERM_ROLE_EDIT = 'update-role';
exports.PERM_ROLE_SHOW = 'show-roles';
exports.PERM_ROLE_DELETE = 'delete-role';
exports.PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION = 'role-manage-permission-action';
//Permission Groups
exports.PERM_PERMISSION_GROUP_MENU = 'view-permission-groups-management-menu';
exports.PERM_PERMISSION_GROUP_LIST = 'list-permission-groups';
exports.PERM_PERMISSION_GROUP_CREATE = 'create-permission-group';
exports.PERM_PERMISSION_GROUP_EDIT = 'update-permission-group';
exports.PERM_PERMISSION_GROUP_DELETE = 'delete-permission-group';
exports.PERM_PERMISSION_GROUP_SHOW = 'show-permission-groups';
exports.PERM_PERMISSION_GROUP_ACTION_EXPORT = 'export-permission-group';
//Permissions
exports.PERM_PERMISSION_MENU = 'view-permissions-management-menu';
exports.PERM_PERMISSION_LIST = 'list-permissions';
exports.PERM_PERMISSION_CREATE = 'create-permission';
exports.PERM_PERMISSION_EDIT = 'update-permission';
exports.PERM_PERMISSION_SHOW = 'show-permissions';
exports.PERM_PERMISSION_DELETE = 'delete-permission';
