var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DefaultListVerticalActionButton from '../../button/DefaultListVerticalActionButtons';
import DefaultDropdownGatedEditButton from '../../button/DefaultDropdownGatedEditButton';
import DefaultDropdownGatedShowButton from '../../button/DefaultDropdownGatedShowButton';
import DefaultDropdownGatedDeleteButton from '../../button/DefaultDropdownGatedDeleteButton';
import { usePermissions } from 'react-admin';
var DefaultDropdownListActionButton = function (_a) {
    var allowedPermissionNames = _a.allowedPermissionNames, hasButtonPermission = _a.hasButtonPermission, children = _a.children, restProps = __rest(_a, ["allowedPermissionNames", "hasButtonPermission", "children"]);
    var _b = usePermissions(), loaded = _b.loaded, permissions = _b.permissions;
    if (loaded) {
        return (_jsxs(DefaultListVerticalActionButton, { children: [_jsx(DefaultDropdownGatedEditButton, __assign({}, restProps, { allowedPermissionName: allowedPermissionNames.edit, hasEdit: hasButtonPermission.edit, permissions: permissions }), void 0), _jsx(DefaultDropdownGatedShowButton, __assign({}, restProps, { allowedPermissionName: allowedPermissionNames.show, hasShow: hasButtonPermission.show, permissions: permissions }), void 0), _jsx(DefaultDropdownGatedDeleteButton, __assign({}, restProps, { allowedPermissionName: allowedPermissionNames.delete, hasDelete: hasButtonPermission.delete, permissions: permissions }), void 0), children] }, void 0));
    }
    else {
        return null;
    }
};
export default DefaultDropdownListActionButton;
