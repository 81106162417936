var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    menuItem: {
        padding: '0px',
        height: 'auto'
    }
}); });
var DefaultDropdownGatedButton = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    return _jsx(MenuItem, __assign({ className: classes.menuItem }, { children: children }), void 0);
};
export default DefaultDropdownGatedButton;
