import MediaIcon from '@material-ui/icons/Backup';
import MediaList from './MediaList';
import { PERM_MEDIA_LIST } from '../../constants/permissions';
var allowedPermissions = {
    list: PERM_MEDIA_LIST
};
var MediaResource = {
    name: 'Media',
    list: MediaList,
    icon: MediaIcon,
    allowedPermissions: allowedPermissions
};
export { MediaIcon };
export default MediaResource;
