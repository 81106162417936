var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card/index';
import CardContent from '@material-ui/core/CardContent/index';
import Button from '@material-ui/core/Button/index';
import { useTranslate, changeLocale, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { changeTheme } from '../../state/actions/defaultThemeActions';
var useStyles = makeStyles(function (theme) { return ({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' }
}); });
var DefaultUserPreferences = function (_a) {
    var theme = _a.theme, locale = _a.locale, changeTheme = _a.changeTheme, changeLocale = _a.changeLocale;
    var translate = useTranslate();
    var classes = useStyles();
    return (_jsxs(Card, { children: [_jsx(Title, { title: translate('pos.configuration') }, void 0), _jsxs(CardContent, { children: [_jsx("div", __assign({ className: classes.label }, { children: translate('pos.theme.name') }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: theme === 'light' ? 'primary' : 'default', onClick: function () { return changeTheme('light'); } }, { children: translate('pos.theme.light') }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: theme === 'dark' ? 'primary' : 'default', onClick: function () { return changeTheme('dark'); } }, { children: translate('pos.theme.dark') }), void 0)] }, void 0), _jsxs(CardContent, { children: [_jsx("div", __assign({ className: classes.label }, { children: translate('pos.language') }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: locale === 'en' ? 'primary' : 'default', onClick: function () { return changeLocale('en'); } }, { children: "en" }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: locale === 'it' ? 'primary' : 'default', onClick: function () { return changeLocale('it'); } }, { children: "it" }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: locale === 'fr' ? 'primary' : 'default', onClick: function () { return changeLocale('fr'); } }, { children: "fr" }), void 0), _jsx(Button, __assign({ variant: "raised", className: classes.button, color: locale === 'de' ? 'primary' : 'default', onClick: function () { return changeLocale('de'); } }, { children: "de" }), void 0)] }, void 0)] }, void 0));
};
var mapStateToProps = function (state) { return ({
    theme: state.theme,
    locale: state.i18n.locale
}); };
var enhance = compose(connect(mapStateToProps, {
    changeLocale: changeLocale,
    changeTheme: changeTheme
}));
export default enhance(DefaultUserPreferences);
