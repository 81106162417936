var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { TabbedForm, FormTab, TextInput, TextField, Edit, Toolbar, useTranslate } from 'react-admin';
import { useLocalStorage } from 'react-use';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import validation from './Validation';
import { styles } from './Style';
import { makeStyles } from '@material-ui/core/styles';
import UserPreferences from './UserPreferences';
import ProfileActions from './ProfileActions';
import CustomSaveButton from './CustomSaveButton';
var useStyles = makeStyles(styles);
var SaveUserData = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation updateUser(\n    $id: ID!\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $password: String\n    $passwordConfirmation: String\n    $settings: JSON\n    $isActive: Boolean\n  ) {\n    data: updateUser(\n      id: $id\n      email: $email\n      firstName: $firstName\n      lastName: $lastName\n      password: $password\n      passwordConfirmation: $passwordConfirmation\n      settings: $settings\n      isActive: $isActive\n    ) {\n      id\n    }\n  }\n"], ["\n  mutation updateUser(\n    $id: ID!\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $password: String\n    $passwordConfirmation: String\n    $settings: JSON\n    $isActive: Boolean\n  ) {\n    data: updateUser(\n      id: $id\n      email: $email\n      firstName: $firstName\n      lastName: $lastName\n      password: $password\n      passwordConfirmation: $passwordConfirmation\n      settings: $settings\n      isActive: $isActive\n    ) {\n      id\n    }\n  }\n"])));
var UserProfileEditTitle = function () {
    var translate = useTranslate();
    return _jsx("span", { children: translate('resources.User.profile.editPagetitle') }, void 0);
};
var ProfileEdit = function (props) {
    var _a = useLocalStorage('userId'), userId = _a[0], setUserId = _a[1];
    var _b = useLocalStorage('user'), user = _b[0], setUser = _b[1];
    var classes = useStyles();
    var currentTheme = user && user.settings && user.settings.theme
        ? user.settings.theme
        : 'light';
    var currentLocal = user && user.settings && user.settings.locale ? user.settings.locale : 'en';
    var _c = useState(currentTheme), userSelectedTheme = _c[0], setUserSelectedTheme = _c[1];
    var _d = useState(currentLocal), userSelectedLocal = _d[0], setUserSelectedLocal = _d[1];
    var _e = useMutation(SaveUserData), saveUserData = _e[0], data = _e[1].data;
    var updateUserData = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUserData({
                        variables: {
                            id: record.id,
                            email: record.email,
                            firstName: record.firstName,
                            lastName: record.lastName,
                            settings: {
                                theme: userSelectedTheme,
                                locale: userSelectedLocal
                            },
                            password: record.password,
                            passwordConfirmation: record.passwordConfirmation
                        }
                    })];
                case 1:
                    _a.sent();
                    user.settings.theme = userSelectedTheme;
                    user.settings.locale = userSelectedLocal;
                    return [4 /*yield*/, localStorage.setItem('user', JSON.stringify(user))];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var CustomToolbar = function (props) { return (_jsx(Toolbar, __assign({}, props, { style: { clear: 'left' } }, { children: _jsx(CustomSaveButton, __assign({ handleSave: updateUserData }, props), void 0) }), void 0)); };
    var staticContext = props.staticContext, restProps = __rest(props, ["staticContext"]);
    return (_jsx(Edit, __assign({}, restProps, { actions: _jsx(ProfileActions, { href: "/#/my-profile", buttonTitle: "resources.User.profile.buttons.show" }, void 0), title: _jsx(UserProfileEditTitle, {}, void 0), id: userId ? userId.toString() : userId, resource: "User", basePath: "/my-profile/edit", className: classes.marginTop20 }, { children: _jsxs(TabbedForm, __assign({ toolbar: _jsx(CustomToolbar, {}, void 0) }, { children: [_jsxs(FormTab, __assign({ label: "resources.User.profile.tabs.generalInformation" }, { children: [_jsx(TextInput, { source: "firstName", formClassName: classes.clearWithFloatLeft, validate: validation.validateFirstName }, void 0), _jsx(TextInput, { source: "lastName", formClassName: classes.floatLeftWithMarginLeft, validate: validation.validateLastName }, void 0), _jsx(TextField, { type: "email", source: "email", formClassName: classes.clearWithFloatLeft }, void 0)] }), void 0), _jsxs(FormTab, __assign({ label: "resources.User.profile.tabs.changePassword", path: "password" }, { children: [_jsx(TextInput, { type: "password", source: "password", formClassName: classes.clearWithFloatLeft }, void 0), _jsx(TextInput, { type: "password", source: "passwordConfirmation", formClassName: classes.floatLeftWithMarginLeft, validate: validation.matchPassword }, void 0)] }), void 0), _jsx(FormTab, __assign({ label: "resources.User.profile.tabs.settings", path: "settings" }, { children: _jsx(UserPreferences, { setUserSelectedTheme: setUserSelectedTheme, setUserSelectedLocal: setUserSelectedLocal }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default ProfileEdit;
var templateObject_1;
