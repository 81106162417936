var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CreatePermissionsGridList from './CreatePermissionsGridList';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: '100%'
    },
    permissionGroupHeading: {
        marginTop: '15px',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        fontStyle: 'italic'
    },
    summary: {
        display: 'block'
    }
}); });
var CreateSinglePermissionGroup = function (_a) {
    var permissionGroup = _a.permissionGroup, addOrRemoveSinglePermission = _a.addOrRemoveSinglePermission, addOrRemoveMultiplePermissions = _a.addOrRemoveMultiplePermissions, props = _a.props;
    var classes = useStyles();
    var _b = useState(permissionGroup.permissions), permissions = _b[0], setPermissions = _b[1];
    var _c = useState(permissionGroup.children), childPermissionGroups = _c[0], setChildPermissionGroups = _c[1];
    var _d = useState(permissionGroup.isChecked), isSelected = _d[0], setSelected = _d[1];
    var _e = useState(false), isExpanded = _e[0], setExpanded = _e[1];
    var _f = useState([]), childPermissionsArray = _f[0], setChildPermissionsArray = _f[1];
    var isInitialMount = useRef(true);
    useEffect(function () {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else {
            setSelected(permissionGroup.isChecked);
            togglePermissions(permissionGroup.isChecked);
            toggleChildPermissionGroups(permissionGroup.isChecked);
        }
    }, [permissionGroup.isChecked]);
    var togglePermissions = function (isChecked) {
        if (permissions) {
            var newPermissions = permissions.map(function (permission) {
                return Object.assign({}, permission, { isChecked: isChecked });
            });
            setPermissions(newPermissions);
        }
    };
    var toggleChildPermissionGroups = function (isChecked) {
        if (childPermissionGroups) {
            var newChildPermissionGroups = childPermissionGroups.map(function (childPermissionGroup) {
                return Object.assign({}, childPermissionGroup, { isChecked: isChecked });
            });
            setChildPermissionGroups(newChildPermissionGroups);
        }
    };
    var collectChildPermissions = function (childPermissionGroups) {
        childPermissionsArray.length = 0;
        console.log(childPermissionsArray);
        var childPermissions = childPermissionGroups
            ? childPermissionGroups.map(function (childPermissionGroup) {
                return childPermissionGroup.children.length > 0
                    ? childPermissionGroup.children.map(function (child) {
                        return child.permissions.map(function (permission) {
                            return childPermissionsArray.push(permission);
                        });
                    })
                    : childPermissionGroup.permissions.map(function (permission) {
                        return childPermissionsArray.push(permission);
                    });
            })
            : [];
        return childPermissionsArray;
    };
    var storeToggledPermissionIds = function (isChecked) {
        var childPermissions = collectChildPermissions(childPermissionGroups);
        var allPermissions = __spreadArray(__spreadArray([], permissions, true), childPermissions, true).flat();
        if (isChecked) {
            addOrRemoveMultiplePermissions(allPermissions, true);
        }
        else {
            addOrRemoveMultiplePermissions(allPermissions, false);
        }
    };
    var handlePermissionGroupChecked = function (event, isChecked) {
        setSelected(isChecked);
        togglePermissions(isChecked);
        toggleChildPermissionGroups(isChecked);
        storeToggledPermissionIds(isChecked);
        setExpanded(true);
    };
    var handlePermissionGroupExpandedCollapsed = function (event, expanded) {
        setExpanded(expanded);
    };
    var renderChildPermissionGroups = function () {
        if (childPermissionGroups && childPermissionGroups.length > 0) {
            return (_jsx("div", { children: childPermissionGroups.map(function (childPermissionGroup) { return (_jsx(CreateSinglePermissionGroup, { permissionGroup: childPermissionGroup, addOrRemoveSinglePermission: addOrRemoveSinglePermission, addOrRemoveMultiplePermissions: addOrRemoveMultiplePermissions, props: props }, "expansion-" + childPermissionGroup.id)); }) }, void 0));
        }
    };
    var isDisabled = !permissionGroup.isActive;
    var permissionGroupTooltip = permissionGroup.description + (isDisabled ? ' - Inactive' : '');
    return (_jsx(Tooltip, __assign({ title: permissionGroupTooltip }, { children: _jsx(FormControl, __assign({ className: classes.root, component: "fieldset", margin: "normal" }, { children: _jsxs(ExpansionPanel, __assign({ className: classes.root, expanded: isExpanded, onChange: handlePermissionGroupExpandedCollapsed }, { children: [_jsx(ExpansionPanelSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}, void 0) }, { children: _jsx(FormControlLabel, { htmlFor: permissionGroup.id + "_" + permissionGroup.name, name: permissionGroup.id + "_" + permissionGroup.name, className: classes.label, value: permissionGroup.id.toString(), checked: isSelected, disabled: isDisabled, onChange: handlePermissionGroupChecked, control: _jsx(Checkbox, { id: permissionGroup.id + "__" + permissionGroup.name, color: "primary", className: classes.checkbox }, void 0), label: "" + permissionGroup.label }, permissionGroup.id + "_" + permissionGroup.name) }), void 0), _jsxs(ExpansionPanelDetails, __assign({ className: classes.summary }, { children: [_jsx(CreatePermissionsGridList, { permissionsList: permissions, addOrRemoveSinglePermission: addOrRemoveSinglePermission, props: props }, void 0), renderChildPermissionGroups()] }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default CreateSinglePermissionGroup;
