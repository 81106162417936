import { ACTION_CHANGE_THEME } from '../actions/defaultThemeActions';
var userStr = localStorage.getItem('user');
var user = userStr ? JSON.parse(userStr) : null;
var userTheme = user && user.settings && user.settings.theme ? user.settings.theme : 'light';
export default (function (previousState, _a) {
    if (previousState === void 0) { previousState = userTheme; }
    var type = _a.type, payload = _a.payload;
    if (type === ACTION_CHANGE_THEME) {
        return payload;
    }
    return previousState;
});
