import { required, minLength, maxLength, email, regex } from 'react-admin';
var validateFirstNameMessage = 'resources.User.validations.firstName.required';
var validateLastNameMessage = 'resources.User.validations.lastName.required';
var validateEmailRequiredMessage = 'resources.User.validations.email.required';
var validatePasswordRequiredMessage = 'resources.User.validations.password.required';
var validatePasswordRegexMessage = 'resources.User.validations.password.regex';
var validateRoleRequiredMessage = 'resources.User.validations.role.required';
var validateFirstName = [
    required(validateFirstNameMessage),
    minLength(2),
    maxLength(25)
];
var validateLastName = [
    required(validateLastNameMessage),
    minLength(2),
    maxLength(25)
];
var validateEmail = [required(validateEmailRequiredMessage), email()];
var validatePassword = [
    required(validatePasswordRequiredMessage),
    regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/, validatePasswordRegexMessage)
];
var validateRole = [required(validateRoleRequiredMessage)];
var validateUser = {
    validateFirstName: validateFirstName,
    validateLastName: validateLastName,
    validateEmail: validateEmail,
    validatePassword: validatePassword,
    validateRole: validateRole
};
export default validateUser;
