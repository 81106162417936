import { downloadCSV, ExportButton } from "react-admin";
import jsonExport from "jsonexport/dist";

const filter = {};

const bankExporter = (forms) => {
  const ordersForExport = forms.map((order) => {
    const { id, name, description, isActive } = order;

    const exportOrder = {
      Id: id,
      Name: name,
      Description: description,
      IsActive: isActive,
    };
    return exportOrder;
  });

  jsonExport(
    ordersForExport,
    {
      headers: ["Id", "Name", "Description", "IsActive"],
      rowDelimiter: ",",
      forceTextDelimiter: true,
    },
    (err, csv) => {
      downloadCSV(csv, "Forms");
    }
  );
};

const ExportForms = ({
  total,
  resource,
  currentSort,
  label,
  className = null,
}) => (
  <ExportButton
    label={label}
    disabled={total === 0}
    resource={resource}
    sort={currentSort}
    filter={filter}
    exporter={bankExporter}
    className={className}
  />
);

export default ExportForms;
