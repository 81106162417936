var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ShowPermissionsGridList from './ShowPermissionsGridList';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: '100%'
    },
    permissionGroupHeading: {
        marginTop: '15px',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        fontStyle: 'italic'
    },
    summary: {
        display: 'block'
    }
}); });
var ShowSinglePermissionGroup = function (_a) {
    var permissionGroup = _a.permissionGroup, props = _a.props;
    var classes = useStyles();
    var _b = useState(permissionGroup.permissions), permissions = _b[0], setPermissions = _b[1];
    var _c = useState(permissionGroup.children), childPermissionGroups = _c[0], setChildPermissionGroups = _c[1];
    var _d = useState(permissionGroup.isChecked), isSelected = _d[0], setSelected = _d[1];
    var _e = useState(false), isExpanded = _e[0], setExpanded = _e[1];
    var isInitialMount = useRef(true);
    useEffect(function () {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else {
            setSelected(permissionGroup.isChecked);
        }
    }, [permissionGroup.isChecked]);
    var handlePermissionGroupExpandedCollapsed = function (event, expanded) {
        setExpanded(expanded);
    };
    var renderChildPermissionGroups = function () {
        if (childPermissionGroups && childPermissionGroups.length > 0) {
            return (_jsx("div", { children: childPermissionGroups.map(function (childPermissionGroup) { return (_jsx(ShowSinglePermissionGroup, { permissionGroup: childPermissionGroup, props: props }, "expansion-" + childPermissionGroup.id)); }) }, void 0));
        }
    };
    var isDisabled = !permissionGroup.isActive;
    var permissionGroupTooltip = permissionGroup.description + (isDisabled ? ' - Inactive' : '');
    return (_jsx(Tooltip, __assign({ title: permissionGroupTooltip }, { children: _jsx(FormControl, __assign({ className: classes.root, component: "fieldset", margin: "normal" }, { children: _jsxs(ExpansionPanel, __assign({ className: classes.root, expanded: isExpanded, onChange: handlePermissionGroupExpandedCollapsed }, { children: [_jsx(ExpansionPanelSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}, void 0) }, { children: _jsx(FormControlLabel, { htmlFor: permissionGroup.id + "_" + permissionGroup.name, name: permissionGroup.id + "_" + permissionGroup.name, className: classes.label, value: permissionGroup.id.toString(), checked: isSelected, disabled: true, control: _jsx(Checkbox, { id: permissionGroup.id + "__" + permissionGroup.name, color: "primary", className: classes.checkbox, disabled: true }, void 0), label: "" + permissionGroup.label }, permissionGroup.id + "_" + permissionGroup.name) }), void 0), _jsxs(ExpansionPanelDetails, __assign({ className: classes.summary }, { children: [_jsx(ShowPermissionsGridList, { permissionsList: permissions }, void 0), renderChildPermissionGroups()] }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default ShowSinglePermissionGroup;
