import ListAltIcon from "@material-ui/icons/ListAlt";
import FormList from "./list/FormList";
import FormShow from "./FormShow";
import FormEdit from "./FormEdit";

import {
  PERM_FORMS_LIST,
  PERM_FORMS_SHOW,
  PERM_FORMS_EDIT,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORMS_LIST,
  show: PERM_FORMS_SHOW,
  edit: PERM_FORMS_EDIT,
};

const formResource = {
  name: "Form",
  list: FormList,
  edit: FormEdit,
  show: FormShow,
  icon: ListAltIcon,
  allowedPermissions: allowedPermissions,
};

export { formResource };
