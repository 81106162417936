export var PermissionShowStyle = {
    one_line_first_element: {
        display: 'inline-block',
        width: '50%'
    },
    one_line_second_element: {
        display: 'inline-block',
        width: '50%'
    }
};
