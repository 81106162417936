var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DownloadIcon from '@material-ui/icons/GetApp';
import { useTranslate, useListContext } from 'react-admin';
var defaultIcon = _jsx(DownloadIcon, {}, void 0);
var defaultServerUrl = "" + process.env.REACT_APP_SERVER_ENDPOINT;
var ServerExportButton = function (props) {
    var _a = props.downloadApiUrl, downloadApiUrl = _a === void 0 ? '' : _a, _b = props.serverUrl, serverUrl = _b === void 0 ? defaultServerUrl : _b, onClick = props.onClick, _c = props.label, label = _c === void 0 ? 'ra.action.export' : _c, _d = props.icon, icon = _d === void 0 ? defaultIcon : _d, rest = __rest(props, ["downloadApiUrl", "serverUrl", "onClick", "label", "icon"]);
    var translate = useTranslate();
    var total = useListContext(props).total;
    var downloadUrl = "" + defaultServerUrl + downloadApiUrl + "?token=" + localStorage.getItem('jwt_token');
    var hasRecords = total > 0;
    if (hasRecords) {
        return (_jsxs("a", __assign({ href: downloadUrl, className: "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary", style: {
                marginLeft: '5px',
                marginRight: '5px',
                padding: '4px 5px'
            }, target: "_blank", rel: "noopener noreferrer" }, { children: [icon, " ", translate(label)] }), void 0));
    }
    else {
        return null;
    }
};
export { ServerExportButton };
