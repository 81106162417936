import {
  required,
  minLength,
  maxLength,
} from "react-admin";

const mandatory = [
  required(),
  minLength(2),
  maxLength(50)
];

export const validations = {
  mandatory,
};
