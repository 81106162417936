import RoleIcon from '@material-ui/icons/RecentActors';
import RoleList from './RoleList';
import RoleEdit from './RoleEdit';
import RoleCreate from './RoleCreate';
import { PERM_ROLE_LIST, PERM_ROLE_CREATE, PERM_ROLE_EDIT, PERM_ROLE_SHOW } from '@bedrock/ra-core/dist/lib/modules/access/constants/permissions';
import RoleShow from './RoleShow';
var allowedPermissions = {
    list: PERM_ROLE_LIST,
    create: PERM_ROLE_CREATE,
    edit: PERM_ROLE_EDIT,
    show: PERM_ROLE_SHOW
};
var roleResource = {
    name: 'Role',
    icon: RoleIcon,
    list: RoleList,
    edit: RoleEdit,
    show: RoleShow,
    create: RoleCreate,
    allowedPermissions: allowedPermissions
};
export default roleResource;
