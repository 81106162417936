var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslate, TabbedForm, FormTab, TextInput, useEditController, Toolbar, usePermissions, Edit, SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Validations from './Validations';
import { styles } from './Style';
import Button from '@material-ui/core/Button';
import BackButtonIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import RolePermissionsManagerInput from '../../components/permissionsmanager/RolePermissionsManagerInput';
import { SaveRoleButton } from './SaveRoleButton';
import { hasPermission } from '../../../../helpers';
import { PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION } from '../../constants/permissions';
import { DefaultCrudToolbar, EditViewToolbarButton } from '../../../../components';
var useStyles = makeStyles(styles);
var RoleTitle = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    return (_jsxs("span", { children: [translate('resources.Role.name', { smart_count: 1 }), ' ', record ? "" + record.name : ''] }, void 0));
};
var CustomEditViewToolbarButton = function (_a) {
    var showBackButton = _a.showBackButton, backbuttonProps = _a.backbuttonProps, EditButton = _a.EditButton, controllerProps = _a.controllerProps, record = _a.record, props = __rest(_a, ["showBackButton", "backbuttonProps", "EditButton", "controllerProps", "record"]);
    var translate = useTranslate();
    var BackButton = function () { return (_jsxs(Button, __assign({ color: "primary", component: Link, to: props.basePath, style: { marginLeft: '5px', marginRight: '5px' } }, backbuttonProps, { children: [_jsx(BackButtonIcon, { style: { paddingRight: '5px', fontSize: '20px' } }, void 0), translate('resources.buttons.back')] }), void 0)); };
    var CustomSaveButton = function () { return (_jsx(SaveRoleButton, __assign({ label: "Save", record: record, roleId: props === null || props === void 0 ? void 0 : props.id }, props, { pristine: false }), void 0)); };
    return (_jsxs(Toolbar, __assign({}, props, { children: [EditButton && _jsx(CustomSaveButton, {}, void 0), showBackButton && _jsx(BackButton, {}, void 0)] }), void 0));
};
var TabbedRoleForm = function (_a) {
    var controllerProps = _a.controllerProps, classes = _a.classes, isFirstRender = _a.isFirstRender, setInitialRender = _a.setInitialRender, isLoading = _a.isLoading, props = _a.props;
    return (_jsx(Edit, __assign({}, props, controllerProps, { actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(RoleTitle, {}, void 0), resource: "Role" }, { children: _jsxs(TabbedForm, __assign({ toolbar: _jsx(CustomEditViewToolbarButton, { EditButton: true, showBackButton: true }, void 0) }, { children: [_jsxs(FormTab, __assign({ label: "resources.Role.tabs.role" }, { children: [_jsx(TextInput, { source: "name", formClassName: classes.width544, validate: Validations.name }, void 0), _jsx(TextInput, { source: "description", multiline: true, formClassName: classes.width544, validate: Validations.description, resettable: true }, void 0)] }), void 0), _jsx(FormTab, __assign({ label: "resources.Role.tabs.permissions" }, { children: _jsx(RolePermissionsManagerInput, { roleId: props.id, setInitialRender: setInitialRender, isFirstRender: isFirstRender, props: props }, void 0) }), void 0)] }), void 0) }), void 0));
};
var SimpleRoleForm = function (_a) {
    var controllerProps = _a.controllerProps, classes = _a.classes, props = _a.props;
    return (_jsx(Edit, __assign({}, props, controllerProps, { actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(RoleTitle, {}, void 0), resource: "Role" }, { children: _jsxs(SimpleForm, __assign({ toolbar: _jsx(EditViewToolbarButton, { allowedPermissionNames: { delete: false } }, void 0) }, { children: [_jsx(TextInput, { source: "name", formClassName: classes.width544, validate: Validations.name }, void 0), _jsx(TextInput, { source: "description", multiline: true, formClassName: classes.width544, validate: Validations.description, resettable: true }, void 0)] }), void 0) }), void 0));
};
var RoleEdit = function (props) {
    var classes = useStyles();
    var controllerProps = useEditController(props);
    var permissions = usePermissions().permissions;
    var allowManagePermissionsFromRoleScreen = hasPermission(permissions, PERM_MANAGE_PERMISSIONS_FROM_ROLE_ACTION);
    var _a = useState(true), isFirstRender = _a[0], setFirstRender = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var setInitialRender = function (values) {
        setFirstRender(false);
    };
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            setIsLoading(false);
        }, 2000);
        return function () { return clearTimeout(timer); };
    }, [isLoading]);
    if (allowManagePermissionsFromRoleScreen) {
        return (_jsx(TabbedRoleForm, __assign({ controllerProps: controllerProps }, props, { props: props, classes: classes, isFirstRender: isFirstRender, setInitialRender: setInitialRender, loading: loading, isLoading: isLoading }), void 0));
    }
    else {
        return (_jsx(SimpleRoleForm, __assign({ controllerProps: controllerProps }, props, { props: props, classes: classes }), void 0));
    }
};
export default RoleEdit;
