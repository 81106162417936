var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
var SinglePermission = function (_a) {
    var permission = _a.permission, addOrRemoveSinglePermission = _a.addOrRemoveSinglePermission;
    var _b = useState(permission.isChecked), isSelected = _b[0], setSelected = _b[1];
    useEffect(function () {
        setSelected(permission.isChecked);
    }, [permission.isChecked]);
    var handlePermissionChecked = function (event, isChecked) {
        handlePermissionToggle(isChecked);
    };
    var handlePermissionToggle = function (isChecked) {
        setSelected(isChecked);
        if (isChecked) {
            addOrRemoveSinglePermission(permission, true);
        }
        else {
            addOrRemoveSinglePermission(permission, false);
        }
    };
    var isDisabled = !permission.isActive;
    var permissionTooltip = permission.description + (isDisabled ? ' - Inactive' : '');
    return (_jsx(Tooltip, __assign({ title: permissionTooltip }, { children: _jsx(FormControl, __assign({ component: "fieldset", margin: "normal" }, { children: _jsx(FormControlLabel, { htmlFor: permission.id + "_" + permission.name, name: permission.id + "_" + permission.name, value: permission.id.toString(), disabled: isDisabled, checked: isSelected, onChange: handlePermissionChecked, label: "" + permission.label, control: _jsx(Checkbox, { id: permission.id + "__" + permission.name, color: "primary" }, void 0) }, permission.id + "_" + permission.name) }), void 0) }), void 0));
};
export default SinglePermission;
