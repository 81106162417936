var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslate, TextInput, useEditController, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Validations from './Validations';
import { styles } from './Style';
import DefaultCrudToolbar from '../../../../components/customToolbar/DefaultCrudToolbar';
import EditViewToolbarButton from '../../../../components/customToolbar/EditViewToolbarButton';
import { PERM_USER_DELETE } from '../../constants/permissions';
import DefaultEdit from '../../../../components/edit/defaultEdit/DefaultEdit';
var useStyles = makeStyles(styles);
var UserTitle = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    return (_jsxs("span", { children: [translate('resources.User.name', { smart_count: 1 }), ' ', record ? "\"" + record.firstName + "\"" : ''] }, void 0));
};
var matchPassword = function (value, allValues) {
    return value !== allValues.password
        ? 'This field must match with your password.'
        : undefined;
};
var UserEdit = function (props) {
    var classes = useStyles();
    var controllerProps = useEditController(props);
    return (_jsxs(DefaultEdit, __assign({ actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(UserTitle, {}, void 0), toolbar: _jsx(EditViewToolbarButton, { allowedPermissionNames: { delete: PERM_USER_DELETE } }, void 0) }, props, controllerProps, { children: [_jsx(TextInput, { autoFocus: true, source: "firstName", formClassName: classes.displayInlineBlock, validate: Validations.validateFirstName }, void 0), _jsx(TextInput, { source: "lastName", formClassName: classes.marginLeftDisplayInlineBlock, validate: Validations.validateLastName }, void 0), _jsx(TextInput, { type: "email", source: "email", formClassName: classes.width544, validate: Validations.validateEmail }, void 0), _jsx(TextInput, { type: "password", source: "password", formClassName: classes.displayInlineBlock }, void 0), _jsx(TextInput, { type: "password", source: "passwordConfirmation", formClassName: classes.marginLeftDisplayInlineBlock, validate: matchPassword }, void 0), _jsx(ReferenceArrayInput, __assign({ source: "rolesIds", reference: "Role", sort: { field: 'name', order: 'ASC' } }, { children: _jsx(AutocompleteArrayInput, { translateChoice: false, allowDuplicates: false, allowEmpty: false, optionText: "name" }, void 0) }), void 0)] }), void 0));
};
export default UserEdit;
