import { TextField, Filter, SearchInput } from "react-admin";
import DefaultDropdownListActionButton from "@bedrock/ra-core/dist/esm/components/list/listAction/DefaultDropdownListActionButton";
import DefaultGatedListActions from "@bedrock/ra-core/dist/esm/components/list/actions/DefaultGatedListActions";
import DefaultList from "@bedrock/ra-core/dist/esm/components/list/defaultList/DefaultList";
import { branchExporter } from "./branchExporter";

import {
  PERM_BRANCH_EDIT,
  PERM_BRANCH_SHOW,
  PERM_BRANCH_CREATE,
  PERM_BRANCH_DELETE,
  PERM_BRANCH_EXPORT,
} from "@smartforms/commonui";

const allowedPermissionNames = {
  edit: PERM_BRANCH_EDIT,
  show: PERM_BRANCH_SHOW,
  create: PERM_BRANCH_CREATE,
  delete: PERM_BRANCH_DELETE,
  export: PERM_BRANCH_EXPORT,
};

const DefaultFilter = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const hasButtonPermission = {
  delete: true,
  edit: true,
  show: true,
};

const BranchList = (props) => {
  return (
    <DefaultList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
      filters={<DefaultFilter />}
      actions={
        <DefaultGatedListActions
          allowedPermissionNames={allowedPermissionNames}
          exporter={branchExporter}
        />
      }
    >
      <TextField source="name" sortBy={"name"} />
      <TextField source="notificationEmail" sortBy={"notification_email"} />
      <DefaultDropdownListActionButton
        allowedPermissionNames={allowedPermissionNames}
        hasButtonPermission={hasButtonPermission}
      />
    </DefaultList>
  );
};

export { BranchList };
