import { jsx as _jsx } from "react/jsx-runtime";
import { usePermissions } from 'react-admin';
import { hasPermission } from '../../helpers/auth';
var DefaultGatedButton = function (_a) {
    var allowedPermissionName = _a.allowedPermissionName, hasButtonPermission = _a.hasButtonPermission, _b = _a.permissions, permissions = _b === void 0 ? null : _b, children = _a.children;
    var ShowButton = function () { return children; };
    var PermissionedButton = function () {
        var _a = usePermissions(), loaded = _a.loaded, permissions = _a.permissions;
        if (loaded &&
            hasButtonPermission &&
            allowedPermissionName !== undefined &&
            hasPermission(permissions, allowedPermissionName)) {
            return _jsx(ShowButton, {}, void 0);
        }
        else {
            return null;
        }
    };
    if (permissions) {
        if (hasButtonPermission &&
            allowedPermissionName !== undefined &&
            hasPermission(permissions, allowedPermissionName)) {
            return _jsx(ShowButton, {}, void 0);
        }
        else {
            return null;
        }
    }
    else {
        return _jsx(PermissionedButton, {}, void 0);
    }
};
export default DefaultGatedButton;
