import { useState } from "react";
import { useTranslate, useRedirect } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textAlignCenter: { textAlign: "center", padding: "5px" },
  overFlowDiv: {
    [theme.breakpoints.down("xs")]: {
      overflowX: "auto",
      width: "94vw",
      margin: "3vw",
    },
  },
  tabelPadding: {
    width: "100%",
    marginTop: "1em",
    backgroundColor: "#fff",
    paddingTop: "10px",
    paddingBottom: "30px",
    borderCollapse: "collapse",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
    textDecoration: {
      textDecoration: "none"
    }
  },
  tableHeader: { display: "flex", justifyContent: "space-between" },
  th: {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    backgroundColor: "#dddddd",
    fontSize: "18px",
  },
  td: {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    fontSize: "16px",
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f2f2f2",
    },
    "&:hover": {
      backgroundColor: "#dddddd",
    },
  },
  fontBold: {
    fontWeight: "bold",
    backgroundColor: "#dddddd",
  },
}));

const DashboardTable = ({ formsSummary }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();

  const handleFormEntryRedirect = (slug) => {
    redirect(slug);
  }

  return (
    <div className={classes.overFlowDiv}>
      <table className={classes.tabelPadding}>
        <tbody>
          <tr className={classes.tr}>
            <th className={classes.th}></th>
            <th className={classes.th}>
              {translate("dashboard.formSummaryStats.totalEntries")}
            </th>
            <th className={classes.th}>
              {translate("dashboard.formSummaryStats.receivedEntries")}
            </th>
            <th className={classes.th}>
              {translate("dashboard.formSummaryStats.processedEntries")}
            </th>
            <th className={classes.th}>
              {translate("dashboard.formSummaryStats.totalMediaSize")}
            </th>
          </tr>
          {formsSummary?.map((item, index) => (
              <tr className={classes.tr} key={`row-${index}`} onClick={() => handleFormEntryRedirect(item.formsSlug)}>
                <td className={classes.td}>
                  {item.formName}
                </td>
                <td className={classes.td}>
                  {item.totalFormEntriesCount}
                </td>
                <td className={classes.td}>
                  {item.totalReceivedFormEntriesCount}
                </td>
                <td className={classes.td}>
                  {item.totalProcessedFormEntriesCount}
                </td>
                <td className={classes.td}>
                  {item.mediaFileSize}
                </td>
              </tr>
          ))}
        </tbody>
      </table>
    </div >
  );
};

export default DashboardTable;
