import { TextField, BooleanField, useTranslate } from "react-admin";
import { DefaultCrudToolbar, ShowViewToolbarButton } from "@bedrock/ra-core";
import DefaultShow from "@bedrock/ra-core/dist/esm/components/show/defaultShow/DefaultShow";
import { makeStyles } from "@material-ui/core";
import { show } from "./styles";
import { PERM_FORMS_EDIT } from "@smartforms/commonui";

const FormTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Form.name", { smart_count: 1 })}{" "}
      {record && record.name ? `${record.name}` : ""}
    </span>
  );
};

const useStyles = makeStyles(show);

const FormShow = (props) => {
  const classes = useStyles();

  return (
    <DefaultShow
      {...props}
      actions={<DefaultCrudToolbar />}
      title={<FormTitle />}
      ShowViewToolbarButton={
        <ShowViewToolbarButton
          allowedPermissionNames={{ edit: PERM_FORMS_EDIT }}
        />
      }
    >
      <TextField source="name" className={classes.clearWithFloatLeft} />
      <TextField source="description" className={classes.clearWithFloatLeft} />
      <BooleanField source="isActive" formClassName={classes.paddingLeft12} />
    </DefaultShow>
  );
};

export default FormShow;
