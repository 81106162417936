var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex'
    }
}); });
var DefaultListVerticalActionButton = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var anchorRef = useRef(null);
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (_jsx("div", __assign({ className: classes.root }, { children: _jsxs("div", { children: [_jsx(IconButton, __assign({ ref: anchorRef, "aria-controls": "menu-list-grow", "aria-haspopup": "true", onClick: handleToggle, "aria-owns": open ? 'menu-list-grow' : null, color: "primary" }, { children: _jsx(MoreVertIcon, {}, void 0) }), void 0), _jsx(Popper, __assign({ open: open, anchorEl: anchorRef.current, transition: true, placement: "bottom-end", style: { zIndex: '999' } }, { children: _jsx(Paper, __assign({ id: "menu-list-grow" }, { children: _jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsx(MenuList, { children: children }, void 0) }), void 0) }), void 0) }), void 0)] }, void 0) }), void 0));
};
export default DefaultListVerticalActionButton;
