import PermissionsIcon from '@material-ui/icons/Alarm';
import PermissionList from './PermissionList';
import PermissionShow from './PermissionShow';
import { PERM_PERMISSION_LIST, PERM_PERMISSION_CREATE, PERM_PERMISSION_EDIT, PERM_PERMISSION_SHOW } from '../../constants/permissions';
var allowedPermissions = {
    list: PERM_PERMISSION_LIST,
    create: PERM_PERMISSION_CREATE,
    edit: PERM_PERMISSION_EDIT,
    show: PERM_PERMISSION_SHOW
};
var permissionResource = {
    name: 'Permission',
    icon: PermissionsIcon,
    list: PermissionList,
    show: PermissionShow,
    allowedPermissions: allowedPermissions
};
export default permissionResource;
