import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useTranslate, usePermissions, MenuItemLink } from 'react-admin';
import { can } from '../../helpers/auth';
var GatedMenuItemLink = function (_a) {
    var to = _a.to, menuName = _a.menuName, leftIcon = _a.leftIcon, onMenuClick = _a.onMenuClick, allowedPermissionName = _a.allowedPermissionName, open = _a.open, dense = _a.dense;
    var translate = useTranslate();
    var _b = usePermissions(), loaded = _b.loaded, permissions = _b.permissions;
    var renderMenuItemLink = function (permissions) { return (_jsx(Fragment, { children: can(permissions, allowedPermissionName) && (_jsx(MenuItemLink, { to: to, primaryText: translate(menuName, {
                smart_count: 1
            }), leftIcon: leftIcon, onClick: onMenuClick, sidebarIsOpen: open, dense: dense }, void 0)) }, void 0)); };
    return renderMenuItemLink(permissions);
};
// GatedMenuItemLink.propTypes = {
//     onMenuClick: PropTypes.func,
// };
export default GatedMenuItemLink;
