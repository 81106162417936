import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement } from 'react';
import { TopToolbar, usePermissions, CreateButton, ExportButton, RefreshButton } from 'react-admin';
import { hasPermission } from '../../../helpers/auth';
var DefaultGatedListActions = function (_a) {
    var allowedPermissionNames = _a.allowedPermissionNames, bulkActions = _a.bulkActions, basePath = _a.basePath, currentSort = _a.currentSort, displayedFilters = _a.displayedFilters, exporter = _a.exporter, filters = _a.filters, filterValues = _a.filterValues, onUnselectItems = _a.onUnselectItems, resource = _a.resource, selectedIds = _a.selectedIds, showFilter = _a.showFilter, total = _a.total, createButtonLabel = _a.createButtonLabel, exportButtonLabel = _a.exportButtonLabel, children = _a.children;
    var _b = usePermissions(), loaded = _b.loaded, permissions = _b.permissions;
    var CreateButtonWithPermission = function () {
        if (loaded &&
            allowedPermissionNames &&
            allowedPermissionNames.create &&
            hasPermission(permissions, allowedPermissionNames.create)) {
            return _jsx(CreateButton, { basePath: basePath, label: createButtonLabel }, void 0);
        }
        else {
            return null;
        }
    };
    var ExportButtonWithPermission = function () {
        if (loaded &&
            exporter &&
            allowedPermissionNames &&
            allowedPermissionNames.export &&
            hasPermission(permissions, allowedPermissionNames.export)) {
            return (_jsx(ExportButton, { label: exportButtonLabel, disabled: total === 0, resource: resource, sort: currentSort, filter: filterValues, exporter: exporter }, void 0));
        }
        else {
            return null;
        }
    };
    return (_jsxs(TopToolbar, { children: [bulkActions &&
                cloneElement(bulkActions, {
                    basePath: basePath,
                    filterValues: filterValues,
                    resource: resource,
                    selectedIds: selectedIds,
                    onUnselectItems: onUnselectItems
                }), filters &&
                cloneElement(filters, {
                    resource: resource,
                    showFilter: showFilter,
                    displayedFilters: displayedFilters,
                    filterValues: filterValues,
                    context: 'button'
                }), children, _jsx(CreateButtonWithPermission, {}, void 0), _jsx(ExportButtonWithPermission, {}, void 0), _jsx(RefreshButton, {}, void 0)] }, void 0));
};
export default DefaultGatedListActions;
