var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from 'react-admin';
import { DefaultGatedEditButton } from '../../../../components';
import DefaultGatedShowButton from '../../../../components/button/DefaultGatedShowButton';
import DefaultList from '../../../../components/list/defaultList/DefaultList';
import DefaultGatedListActions from '../../../../components/list/actions/DefaultGatedListActions';
import { PERM_ROLE_EDIT, PERM_ROLE_SHOW, PERM_ROLE_DELETE, PERM_ROLE_CREATE } from '../../constants/permissions';
var allowedPermissionNames = {
    edit: PERM_ROLE_EDIT,
    show: PERM_ROLE_SHOW,
    delete: PERM_ROLE_DELETE,
    create: PERM_ROLE_CREATE
};
var RoleList = function (props) { return (_jsxs(DefaultList, __assign({}, props, { sort: { field: 'name', order: 'ASC' }, bulkActionButtons: false, actions: _jsx(DefaultGatedListActions, { allowedPermissionNames: allowedPermissionNames }, void 0) }, { children: [_jsx(TextField, { source: "name" }, void 0), _jsx(TextField, { source: "description" }, void 0), _jsx(DefaultGatedEditButton, { allowedPermissionName: PERM_ROLE_EDIT, hasEdit: props.hasEdit }, void 0), _jsx(DefaultGatedShowButton, { allowedPermissionName: PERM_ROLE_SHOW, hasShow: props.hasShow }, void 0)] }), void 0)); };
export default RoleList;
