var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormTab, AutocompleteArrayInput, ReferenceArrayInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DefaultCrudToolbar from '../../../../components/customToolbar/DefaultCrudToolbar';
import Validations from './Validations';
import { styles } from './Style';
import { DefaultCreate } from '../../../../components';
var useStyles = makeStyles(styles);
var matchPassword = function (value, allValues) {
    return value !== allValues.password
        ? 'This field must match with your password.'
        : undefined;
};
var UserCreate = function (props) {
    var classes = useStyles();
    return (_jsx(DefaultCreate, __assign({ actions: _jsx(DefaultCrudToolbar, {}, void 0), redirect: "show" }, props, { children: _jsxs(FormTab, __assign({ label: "resources.User.tabs.generalInformation" }, { children: [_jsx(TextInput, { autoFocus: true, source: "firstName", formClassName: classes.displayInlineBlock, validate: Validations.validateFirstName }, void 0), _jsx(TextInput, { source: "lastName", formClassName: classes.marginLeftDisplayInlineBlock, validate: Validations.validateLastName }, void 0), _jsx(TextInput, { type: "email", source: "email", formClassName: classes.width544, validate: Validations.validateEmail }, void 0), _jsx(TextInput, { type: "password", source: "password", formClassName: classes.displayInlineBlock, validate: Validations.validatePassword }, void 0), _jsx(TextInput, { type: "password", source: "passwordConfirmation", formClassName: classes.marginLeftDisplayInlineBlock, validate: matchPassword }, void 0), _jsx(ReferenceArrayInput, __assign({ source: "rolesIds", reference: "Role", sort: { field: 'name', order: 'ASC' } }, { children: _jsx(AutocompleteArrayInput, { translateChoice: false, allowDuplicates: false, allowEmpty: false, optionText: "name" }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default UserCreate;
