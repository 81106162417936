import { useState } from "react";
import { DefaultCrudToolbar, EditViewToolbarButton } from "@bedrock/ra-core";
import DefaultEdit from "@bedrock/ra-core/dist/esm/components/edit/defaultEdit/DefaultEdit";

import {
  FormPageTitle,
  PMSBYFormCreateEditContainer,
} from "@smartforms/commonui";

const PMSBYFormEdit = (props) => {
  const [showSaveButton, setIsShowSaveButton] = useState(false);

  const handleSaveButton = (value) => {
    setIsShowSaveButton(value);
  };

  return (
    <DefaultEdit
      actions={<DefaultCrudToolbar />}
      title={
        <FormPageTitle
          titleName="resources.PMSBYForm.name"
          titleFieldValue="fullName"
        />
      }
      toolbar={
        <EditViewToolbarButton
          allowedPermissionNames={{ delete: false }}
          showEditButton={showSaveButton}
        />
      }
      {...props}
    >
      <PMSBYFormCreateEditContainer
        isFront={false}
        {...props}
        toggleSaveButton={handleSaveButton}
      />
    </DefaultEdit>
  );
};

export default PMSBYFormEdit;
