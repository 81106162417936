import { Route } from "react-router-dom";
import DefaultForgotPassword from "./components/auth/DefaultForgotPassword";
import DefaultResetPassword from "./components/auth/DefaultResetPassword";

export default [
  <Route
    exact
    path="/auth/forgot-password"
    component={DefaultForgotPassword}
    // component={ForgotPassword}
    noLayout
    key="forgotpassword"
  />,
  <Route
    exact
    path="/auth/reset-password/:token"
    component={DefaultResetPassword}
    // component={ResetPassword}
    noLayout
    key="resetpassword"
  />,
];
