export var styles = function (theme) { return ({
    width544: { width: 544 },
    maxWidth544: { maxWidth: 544 }
}); };
export var show = function (theme) {
    var _a;
    return ({
        inlineGridWith50PercentWidth: (_a = {},
            _a[theme.breakpoints.up('xs')] = {
                display: 'inline-grid',
                width: '50%'
            },
            _a[theme.breakpoints.down('xs')] = {
                width: '100%'
            },
            _a)
    });
};
export var saveButton = function (theme) { return ({
    marginLeft: '12px !important'
}); };
