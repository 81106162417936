var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import { useDelete, useNotify, useRedirect, useTranslate, useRefresh, Button, Confirm } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import inflection from 'inflection';
import classnames from 'classnames';
var useStyles = makeStyles(function (theme) { return ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
}); });
var CustomDeleteButton = function (_a) {
    var resource = _a.resource, record = _a.record, basePath = _a.basePath, extraClasses = _a.extraClasses, _b = _a.hasConfirmationDialog, hasConfirmationDialog = _b === void 0 ? true : _b, props = __rest(_a, ["resource", "record", "basePath", "extraClasses", "hasConfirmationDialog"]);
    var notify = useNotify();
    var redirectTo = useRedirect();
    var refresh = useRefresh();
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var classes = useStyles();
    var translate = useTranslate();
    var _d = useDelete(resource, record.id, record, {
        undoable: false,
        onSuccess: function () {
            notify('ra.notification.deleted', 'info', { smart_count: 1 }, true);
            redirectTo({ basePath: basePath });
            refresh();
        },
        onFailure: function (error) {
            notify(typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error', 'warning');
        }
    }), deleteOne = _d[0], loading = _d[1].loading;
    var handleClick = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(Button, __assign({ onClick: hasConfirmationDialog ? handleClick : deleteOne, disabled: loading, label: 'ra.action.delete', className: classnames('ra-delete-button', classes.deleteButton, extraClasses) }, { children: _jsx(DeleteIcon, {}, void 0) }), "button"), _jsx(Confirm, { isOpen: open, loading: loading, title: "ra.message.delete_title", content: "ra.message.delete_content", translateOptions: {
                    name: inflection.humanize(translate("resources." + resource + ".name", {
                        smart_count: 1,
                        _: inflection.singularize(resource)
                    }), true),
                    id: record.id
                }, onConfirm: deleteOne, onClose: handleClose }, void 0)] }, void 0));
};
export default CustomDeleteButton;
