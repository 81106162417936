import { Fragment } from "react";
import { GatedMenuItemLink, GatedSubMenu } from "@bedrock/ra-core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountBalanceSharpIcon from "@material-ui/icons/AccountBalanceSharp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import {
  PERM_FORMS_MENU,
  PERM_BRANCH_MENU,
  PERM_FORM_STATS_MENU,
} from "@smartforms/commonui";

const FormsMenu = ({ onMenuClick, dense, open }) => (
  <Fragment>
    <GatedSubMenu
      menuName={`app.menu.forms`}
      icon={<ListAltIcon />}
      open={open}
      allowedPermissionName={PERM_FORMS_MENU}
      dense={dense}
    >
      <GatedMenuItemLink
        menuName={`resources.Form.menu`}
        to={`/Form`}
        allowedPermissionName={PERM_FORMS_MENU}
        leftIcon={<ListAltIcon />}
        onMenuClick={onMenuClick}
        open={open}
        dense={dense}
      />

      <GatedMenuItemLink
        menuName={`resources.FormStats.menu`}
        to={`/FormStats`}
        allowedPermissionName={PERM_FORM_STATS_MENU}
        leftIcon={<EqualizerIcon />}
        onMenuClick={onMenuClick}
        open={open}
        dense={dense}
      />
    </GatedSubMenu>

    <GatedMenuItemLink
      menuName={`app.menu.branch`}
      to={`/Branch`}
      allowedPermissionName={PERM_BRANCH_MENU}
      leftIcon={<AccountBalanceSharpIcon />}
      onMenuClick={onMenuClick}
      open={open}
      dense={dense}
    />
  </Fragment>
);

export default FormsMenu;
