import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import UserIcon from "@material-ui/icons/PermIdentity";
import ProfileIcon from "@material-ui/icons/DataUsage";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  appBar: {
    backgroundColor: "#f0ba46",
    color: "#000099",
  },
  boldText: {
    paddingLeft: "10px",
    color: "#000099",
    fontWeight: 800,
    /*  [theme.breakpoints.down('sm')]: {
             fontSize: '1rem'
         } */
  },
}));

const CustomUserMenu = (props) => {
  const translate = useTranslate();

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/my-profile"
        primaryText={translate("pos.my_profile")}
        leftIcon={<UserIcon />}
      />
    </UserMenu>
  );
};

const CustomAppBar = (props) => {
  const classes = useStyles(props);

  return (
    <AppBar {...props} userMenu={<CustomUserMenu />} className={classes.appBar}>
      <Typography
        variant="body1"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <Typography className={classes.boldText} variant="h5">
        {process.env.REACT_APP_HEADER_LABEL}
      </Typography>

      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
