var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Filter, TextField, useTranslate, SearchInput } from 'react-admin';
import DefaultGatedDeleteButton from '../../../../components/button/DefaultGatedDeleteButton';
import { PERM_MEDIA_DELETE } from '../../constants/permissions';
import DefaultList from '../../../../components/list/defaultList/DefaultList';
var allowedPermissionNames = {
    delete: PERM_MEDIA_DELETE,
    hasButtonPermission: {
        delete: true
    }
};
var MediaFilter = function (_a) {
    var classes = _a.classes, props = __rest(_a, ["classes"]);
    return (_jsx(Filter, __assign({}, props, { children: _jsx(SearchInput, { source: "q", alwaysOn: true }, void 0) }), void 0));
};
var FileName = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    var downloadUrl = record.downloadUrl + "?token=" + localStorage.getItem('jwt_token');
    return (_jsx("span", { children: _jsx("a", __assign({ href: downloadUrl, download: true, title: translate('resources.Media.titles.clickToDownloadFile') }, { children: _jsx("span", { children: record.fileName }, void 0) }), void 0) }, void 0));
};
var MediaList = function (_a) {
    var permissions = _a.permissions, props = __rest(_a, ["permissions"]);
    return (_jsxs(DefaultList, __assign({}, props, { perPage: 10, permissions: permissions, actions: null, sort: { field: 'createdAt', order: 'DESC' }, bulkActionButtons: false }, { children: [_jsx(FileName, { label: "resources.Media.fields.fileName", sortBy: "fileName" }, void 0), _jsx(TextField, { source: "fileSizeHuman", sortBy: "fileSize" }, void 0), _jsx(TextField, { source: "createdAtHuman", sortBy: "createdAt" }, void 0), _jsx(DefaultGatedDeleteButton, { permissions: permissions, allowedPermissionName: allowedPermissionNames.delete, hasDelete: allowedPermissionNames.hasButtonPermission.delete }, void 0)] }), void 0));
};
export default MediaList;
