import { Edit } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { DefaultCrudToolbar } from "@bedrock/ra-core";

import {
  defaultStyles,
  SavingsFormCreateEditContainer,
  FormPageTitle
} from "@smartforms/commonui";

const useStyles = makeStyles(defaultStyles);

const SavingsEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit 
      actions={<DefaultCrudToolbar />} 
      title={
        <FormPageTitle
          titleName="resources.Common.forms.savings"
        />
      }
      redirect="show"
      {...props}
    >
      <SavingsFormCreateEditContainer
        {...props}
        isFront={false}
        classes={classes}
      />
    </Edit>
  );
};

export { SavingsEdit };
