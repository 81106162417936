import DefaultDropdownListActionButton from "@bedrock/ra-core/dist/esm/components/list/listAction/DefaultDropdownListActionButton";
import { PDFDownloadButton } from "./PDFDownloadButton";

const FormEntryDropdownListActionButton = props => {
  return (
    <DefaultDropdownListActionButton {...props}>
      <PDFDownloadButton {...props} />
    </DefaultDropdownListActionButton>
  );
};

export { FormEntryDropdownListActionButton };
