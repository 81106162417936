var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { NumberField, useTranslate, TextField, BooleanField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { PermissionShowStyle } from './Style';
import DefaultCrudToolbar from '../../../../components/customToolbar/DefaultCrudToolbar';
import DefaultShow from '../../../../components/show/defaultShow/DefaultShow';
var useStyles = makeStyles(PermissionShowStyle);
var PermissionTitle = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    return (_jsxs("span", { children: [translate('resources.Permission.name', { smart_count: 1 }), ' ', record ? "\"" + record.name + "\"" : ''] }, void 0));
};
var PermissionShow = function (props) {
    var classes = useStyles();
    return (_jsxs(DefaultShow, __assign({ actions: _jsx(DefaultCrudToolbar, {}, void 0), title: _jsx(PermissionTitle, {}, void 0) }, props, { children: [_jsx(TextField, { label: "resources.Permission.fields.relationalFields.permissionGroupName", source: "permissionGroup.name" }, void 0), _jsx(TextField, { source: "label", className: classes.one_line_second_element }, void 0), _jsx(TextField, { source: "name", className: classes.one_line_first_element }, void 0), _jsx(TextField, { source: "description", className: classes.one_line_second_element }, void 0), _jsx(TextField, { source: "type", className: classes.one_line_first_element }, void 0), _jsx(NumberField, { source: "sortorder", className: classes.one_line_second_element }, void 0), _jsx(BooleanField, { source: "isActive", className: classes.one_line_first_element }, void 0)] }), void 0));
};
export default PermissionShow;
