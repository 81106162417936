import { formsCustomQueries } from "../../modules/forms/entities/forms/formsCustomQueries";
import {
  formEntriesCustomQueries,
  branchesCustomQueries,
} from "@smartforms/commonui";
import { formStatsCustomQueries } from "../../modules/formStats/formStatsCustomQueries";

const AppCustomQueries = (builtQuery, fetchType, resource, params) => {
  builtQuery = formsCustomQueries(builtQuery, fetchType, resource, params);
  builtQuery = formEntriesCustomQueries(
    builtQuery,
    fetchType,
    resource,
    params
  );
  builtQuery = formStatsCustomQueries(builtQuery, fetchType, resource, params);
  builtQuery = branchesCustomQueries(builtQuery, fetchType, resource, params);

  return builtQuery;
};

export { AppCustomQueries };
