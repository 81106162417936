var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Style';
import ProfileActions from './ProfileActions';
import DefaultShow from '../../components/show/defaultShow/DefaultShow';
var useStyles = makeStyles(styles);
var UserProfileShowTitle = function () {
    var translate = useTranslate();
    return _jsx("span", { children: translate('resources.User.profile.showPagetitle') }, void 0);
};
var ProfileShow = function (props) {
    var classes = useStyles();
    var userIdNum = localStorage.userId;
    var userId = userIdNum ? userIdNum.toString() : userIdNum;
    var staticContext = props.staticContext, restProps = __rest(props, ["staticContext"]);
    return (_jsxs(DefaultShow, __assign({ actions: _jsx(ProfileActions, { href: "/#/my-profile/edit", buttonTitle: "resources.User.profile.buttons.edit" }, void 0), title: _jsx(UserProfileShowTitle, {}, void 0), id: userId, resource: "User", basePath: "/my-profile", className: classes.marginTop20 }, restProps, { children: [_jsx(TextField, { source: "firstName", className: classes.inlineGridWith50PercentWidth }, void 0), _jsx(TextField, { source: "lastName", className: classes.inlineGridWith50PercentWidth }, void 0), _jsx(TextField, { source: "email", className: classes.inlineGridWith50PercentWidth }, void 0)] }), void 0));
};
export default ProfileShow;
