import DescriptionIcon from "@material-ui/icons/Description";
import { BranchList } from "./BranchList";
import { BranchEdit } from "./BranchEdit";

import { BranchShow } from "./BranchShow";
import BranchCreate from "./BranchCreate";
import {
  PERM_BRANCH_LIST,
  PERM_BRANCH_CREATE,
  PERM_BRANCH_EDIT,
  PERM_BRANCH_SHOW,
  PERM_BRANCH_DELETE,
} from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_BRANCH_LIST,
  create: PERM_BRANCH_CREATE,
  edit: PERM_BRANCH_EDIT,
  show: PERM_BRANCH_SHOW,
  delete: PERM_BRANCH_DELETE,
};

const branchResource = {
  name: "Branch",
  list: BranchList,
  create: BranchCreate,
  icon: DescriptionIcon,
  show: BranchShow,
  edit: BranchEdit,
  allowedPermissions: allowedPermissions,
  // options: { label: "FE" },
};

export { branchResource };
