var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent/index';
import Button from '@material-ui/core/Button';
import { useTranslate, useSetLocale, useLocale } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { changeTheme } from '../../state/actions/defaultThemeActions';
var useStyles = makeStyles(function (theme) { return ({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' }
}); });
var UserPreferences = function (_a) {
    var theme = _a.theme, changeTheme = _a.changeTheme, setUserSelectedTheme = _a.setUserSelectedTheme, setUserSelectedLocal = _a.setUserSelectedLocal;
    var translate = useTranslate();
    var classes = useStyles();
    var locale = useLocale();
    var setLocale = useSetLocale();
    var selectedTheme = function (selectedTheme) {
        setUserSelectedTheme(selectedTheme);
    };
    var selectedLocale = function (selectedLocale) {
        setUserSelectedLocal(selectedLocale);
    };
    var arrayOfLocales = [];
    if (process.env.REACT_APP_LOCALES) {
        arrayOfLocales = process.env.REACT_APP_LOCALES.split(',');
    }
    return (_jsxs(Fragment, { children: [_jsxs(CardContent, { children: [_jsx("div", __assign({ className: classes.label }, { children: translate('pos.theme.name') }), void 0), _jsx(Button, __assign({ variant: "contained", className: classes.button, color: theme === 'light' ? 'primary' : 'default', onClick: function (event) {
                            changeTheme('light');
                            selectedTheme('light');
                        } }, { children: translate('pos.theme.light') }), void 0), _jsx(Button, __assign({ variant: "contained", className: classes.button, color: theme === 'dark' ? 'primary' : 'default', onClick: function (event) {
                            changeTheme('dark');
                            selectedTheme('dark');
                        } }, { children: translate('pos.theme.dark') }), void 0)] }, void 0), arrayOfLocales.length <= 1 ? null : (_jsxs(CardContent, { children: [_jsx("div", __assign({ className: classes.label }, { children: translate('pos.language') }), void 0), arrayOfLocales.includes('en') && (_jsx(Button, __assign({ variant: "contained", className: classes.button, color: locale === 'en' ? 'primary' : 'default', onClick: function (event) {
                            setLocale('en');
                            selectedLocale('en');
                        } }, { children: translate('resources.User.profile.languages.english') }), void 0)), arrayOfLocales.includes('it') && (_jsx(Button, __assign({ variant: "contained", className: classes.button, color: locale === 'it' ? 'primary' : 'default', onClick: function (event) {
                            setLocale('it');
                            selectedLocale('it');
                        } }, { children: translate('resources.User.profile.languages.italian') }), void 0)), arrayOfLocales.includes('fr') && (_jsx(Button, __assign({ variant: "contained", className: classes.button, color: locale === 'fr' ? 'primary' : 'default', onClick: function (event) {
                            setLocale('fr');
                            selectedLocale('fr');
                        } }, { children: translate('resources.User.profile.languages.french') }), void 0)), arrayOfLocales.includes('de') && (_jsx(Button, __assign({ variant: "contained", className: classes.button, color: locale === 'de' ? 'primary' : 'default', onClick: function (event) {
                            setLocale('de');
                            selectedLocale('de');
                        } }, { children: translate('resources.User.profile.languages.german') }), void 0))] }, void 0))] }, void 0));
};
var mapStateToProps = function (state) { return ({
    theme: state.theme
}); };
var enhance = compose(connect(mapStateToProps, {
    changeTheme: changeTheme
}));
export default enhance(UserPreferences);
