import UserIcon from '@material-ui/icons/Group';
import UserList from './UserList';
import UserEdit from './UserEdit';
import UserShow from './UserShow';
import UserCreate from './UserCreate';
import { PERM_USER_LIST, PERM_USER_CREATE, PERM_USER_EDIT, PERM_USER_SHOW } from '../../constants/permissions';
var allowedPermissions = {
    list: PERM_USER_LIST,
    create: PERM_USER_CREATE,
    edit: PERM_USER_EDIT,
    show: PERM_USER_SHOW
};
var userResource = {
    name: 'User',
    list: UserList,
    edit: UserEdit,
    show: UserShow,
    create: UserCreate,
    icon: UserIcon,
    allowedPermissions: allowedPermissions
};
export default userResource;
