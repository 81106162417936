var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateSinglePermissionGroup from './CreateSinglePermissionGroup';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) { return ({
    heading: {
        marginTop: '10px',
        fontSize: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular
    }
}); });
var CreatePermissionsGroupCollection = function (_a) {
    var initialPermissionGroups = _a.initialPermissionGroups, addOrRemoveSinglePermission = _a.addOrRemoveSinglePermission, addOrRemoveMultiplePermissions = _a.addOrRemoveMultiplePermissions, props = _a.props;
    var classes = useStyles();
    var _b = useState(initialPermissionGroups), permissionGroups = _b[0], setPermissionGroups = _b[1];
    useEffect(function () {
        setPermissionGroups(initialPermissionGroups);
    }, [initialPermissionGroups]);
    if (permissionGroups) {
        return (_jsxs("div", { children: [_jsx(Typography, __assign({ className: classes.heading }, { children: "Manage Permissions" }), void 0), permissionGroups.map(function (permissionGroup, key) { return (_jsx(CreateSinglePermissionGroup, { permissionGroup: permissionGroup, addOrRemoveSinglePermission: addOrRemoveSinglePermission, addOrRemoveMultiplePermissions: addOrRemoveMultiplePermissions, props: props }, "permissionsmanager-" + key)); })] }, void 0));
    }
};
export default CreatePermissionsGroupCollection;
