import { useTranslate, TextInput, BooleanInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { DefaultCrudToolbar, EditViewToolbarButton } from "@bedrock/ra-core";
import { styles } from "./styles";
import { validations } from "./validations";
import DefaultEdit from "@bedrock/ra-core/dist/esm/components/edit/defaultEdit/DefaultEdit";

const FormTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Form.name", { smart_count: 1 })}{" "}
      {record && record.name ? `"${record.name}"` : ""}
    </span>
  );
};

const useStyles = makeStyles(styles);

const FormEdit = (props) => {
  const classes = useStyles();

  return (
    <DefaultEdit
      actions={<DefaultCrudToolbar />}
      title={<FormTitle />}
      toolbar={
        <EditViewToolbarButton allowedPermissionNames={{ delete: false }} />
      }
      {...props}
    >
      <TextInput
        source="name"
        formClassName={classes.width544}
        validate={validations.mandatory}
      />
      <TextInput
        source="description"
        formClassName={classes.width544}
      />
      <TextInput
        source="slug"
        formClassName={classes.width544}
        validate={validations.mandatory}
      />
      <BooleanInput source="isActive" formClassName={classes.paddingLeft12} />
    </DefaultEdit>
  );
};

export default FormEdit;
