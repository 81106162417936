import DescriptionIcon from "@material-ui/icons/Description";
import { FormStatsList } from "./formStatsList";

import { PERM_FORM_SATATS_LIST } from "@smartforms/commonui";

const allowedPermissions = {
  list: PERM_FORM_SATATS_LIST,
};

const formStatResource = {
  name: "FormStats",
  list: FormStatsList,
  icon: DescriptionIcon,
  allowedPermissions: allowedPermissions,
};

export { formStatResource };
