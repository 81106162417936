import { Fragment } from "react";
import { BulkDeleteWithConfirmWithUndoButton } from "@bedrock/ra-core/dist/esm/components/button";

const PostBulkActionButtons = ({
  bulkExportPermissionName,
  bulkDeletePermissionName,
  bulkExporter,
  ...rest
}) => (
  <Fragment>
    <BulkDeleteWithConfirmWithUndoButton
      {...rest}
      permissionName={bulkDeletePermissionName}
    />
  </Fragment>
);

export { PostBulkActionButtons };
