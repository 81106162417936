import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  media: {
    height: "25em",
    margin: 30,
  },
  boldText: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const DefaultCard = ({
  data,
  children
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const firstName = data?.firstName;
  const lastName = data?.lastName;

  return (
    <div>
      <Card variant="outlined">
        <CardHeader title={`Welcome ${firstName} ${lastName}`} subheader="" />
        <CardActionArea>
          {children}
        </CardActionArea>
      </Card>
    </div>
  );
};

export { DefaultCard };
